import { Dropdown } from "react-bootstrap";
import { IconButtonDelete, IconButtonDuplicate } from "../../../../../shared/component/button/IconButton";
import { DropdownWithLabel } from "../../../../../shared/component/dropdown/DropdownInput";
import { InputOnly, InputWithLabel, TextAreaWithLabel } from "../../../../../shared/component/input/Input";
import { contentLayoutChoices, scaleLabelData } from "../contentData";
import { useEffect, useState } from "react";
import { ButtonContent, ButtonLink } from "../../../../../shared/component/button/Button";
import ModalAddContentFrom from "../tab_content_components/ModalAddContent";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../../../redux/RootAction";
import useRefs from "react-use-refs";
import screenToast from "../../../../../shared/hook/ScreenToast";
import screenAlert from "../../../../../shared/hook/SweetAlert";


function SectionViewUpdate({ setIsEditing,handleCopySection }) {
   const [addContentModal, setAddContentModal] = useState(false);
   const contentView = useSelector((state) => state.update_view_content_multi_rater);
   const sectionReducer = useSelector((state) => state.update_section_multi_rater.length > 0 && state.update_section_multi_rater.find((el) => el.section_id == contentView.section_id));
   const dispatch = useDispatch();
   const [layoutRef, isUsingScaleRef] = useRefs();
   const scaleLabel = useSelector((state) => state.update_scale_choice_multi_rater.length > 0 && state.update_scale_choice_multi_rater.find((el) => el.section_id == contentView.section_id));
   const [counterScale, setCounterScale] = useState(4);
   const showToast = screenToast();
   const showAlert = screenAlert();


   useEffect(() => {
      if (scaleLabel && scaleLabel.content_choice.length > 0) {
         let counterSclaeCurrent = scaleLabel.content_choice[scaleLabel.content_choice.length - 1].scale_id;
         setCounterScale(counterSclaeCurrent + 1);
      }
   }, [sectionReducer, scaleLabel]);

   const handleDeleteSection = () => {
      dispatch(rootAction.updateMultiRater.clearContentViewMRAction());
      dispatch(rootAction.updateMultiRater.deleteSectionMRAction(sectionReducer.section_id));
      setIsEditing(true);
   }

   const handleOnChangeText = (e, scale_id) => {
      if (e.target.id == 'choice_text') {
         dispatch(rootAction.updateMultiRater.editScaleChoiceMRAction({
            section_key: contentView.section_id,
            scale_key: scale_id,
            field: e.target.id,
            payload: e.target.value
         }));
      } else {
         dispatch(rootAction.updateMultiRater.editSectionMRAction({
            key: contentView.section_id,
            field: e.target.id,
            payload: e.target.value
         }));
      }
      setIsEditing(true);
   }

   const handleOnSelect = (ref, val) => {
      if (ref.current.id == 'using_scale') {
         if (val == 1) {
            dispatch(rootAction.updateMultiRater.editSectionMRAction({
               key: contentView.section_id,
               field: ref.current.id,
               payload: val
            }));

            dispatch(rootAction.updateMultiRater.editSectionMRAction({
               key: contentView.section_id,
               field: 'scale_range',
               payload: scaleLabelData().content_choice.length
            }))

            dispatch(rootAction.updateMultiRater.setScaleChoiceMRAction(scaleLabelData(contentView.section_id)))
         } else {
            let isUsingScale = false;

            for (let i = 0; i < sectionReducer.content.length; i++) {
               if (sectionReducer.content[i].content_type === 'scale') {
                  isUsingScale = true;
                  break;
               }
            }

            if (isUsingScale) {
               showToast.error("Content with scale exists; please delete it first");
            } else {
               dispatch(rootAction.updateMultiRater.editSectionMRAction({
                  key: contentView.section_id,
                  field: ref.current.id,
                  payload: val
               }));

               dispatch(rootAction.updateMultiRater.clearScaleChoiceMRAction(contentView.section_id)) && dispatch(rootAction.updateMultiRater.editSectionMRAction({
                  key: contentView.section_id,
                  field: 'scale_range',
                  payload: 0
               }));
            }
         }
      } else {
         dispatch(rootAction.updateMultiRater.editSectionMRAction({
            key: contentView.section_id,
            field: ref.current.id,
            payload: val
         }));
      }
      setIsEditing(true);
   }

   const handleAddScaleChoie = () => {
      if (scaleLabel.content_choice.length >= 7) {
         showToast.error('Maximum scale choice reached');
      } else {
         dispatch(rootAction.updateMultiRater.addScaleChoiceMRAction({
            key: contentView.section_id,
            payload: {
               scale_id: counterScale,
               choice_code: '',
               choice_text: '',
               choice_score: scaleLabel.content_choice.length + 1
            }
         }));
         dispatch(rootAction.updateMultiRater.editSectionMRAction({
            key: contentView.section_id,
            field: 'scale_range',
            payload: scaleLabel.content_choice.length + 1
         }));
         setCounterScale(prevState => prevState + 1);
      }
      setIsEditing(true);
   }

   const handleDeleteScaleChoice = (scale_id) => {
      dispatch(rootAction.updateMultiRater.deleteScaleChoiceMRAction({
         section_key: contentView.section_id,
         scale_key: scale_id
      }));
      dispatch(rootAction.updateMultiRater.editSectionMRAction({
         key: contentView.section_id,
         field: 'scale_range',
         payload: scaleLabel.content_choice.length - 1
      }));
      setIsEditing(true);
   }

   const handleAddContent = () => {
      let content = {
         content_id: sectionReducer.content.length + 1,
         content_type: '',
         content_code: '',
         content_name: 'Question ' + (sectionReducer.content.length + 1),
         item: '',
         item_in_eng: '',
         help: '',
         score_same_as_scale: 1,
         score: 0,
         goal_id: 0,
         group_survey: '',
         dimension: '',
         aspect: '',
         is_mandatory: 0,
         has_box_answer: 0,
         action_for_strength: '',
         action_for_weakness: '',
         content_choice: []
      }

      dispatch(rootAction.updateMultiRater.setContentMRAction({
         key: sectionReducer.section_id,
         payload: content
      }));
      dispatch(rootAction.updateMultiRater.setContentViewMRAction({
         section_id: sectionReducer.section_id,
         content_id: content.content_id
      }));
      setIsEditing(true);
   }

   const alertToDuplicate = () => {
      showAlert.info({
          text: `Are you sure to duplicate ${sectionReducer.section_name} ?`,
          buttons: {
              cancel: {
                  text: "Cancel",
                  className: "bg-white text-dark"
              },
              confirm: {
                  text: "Confirm",
                  value: true,
                  className: "bg-success"
              }
          }
      }).then(value => {
          if (value) {
              handleCopySection(sectionReducer,scaleLabel)
          }
      });
  }


   return (
      <div className="col-9" style={{ minHeight: '100vh' }}>

         <div className="col-12 border-bottom pb-1 d-flex justify-content-between align-items-center" style={{ boxShadow: 'inset 0 -0.1rem 0 0 rgb(0,0,0,0.2)', height: '6vh' }}>
            <div>

            </div>
            <h5>
               Content Structure - Section
            </h5>
            <div>
                <IconButtonDuplicate onClick={alertToDuplicate}/>
                <IconButtonDelete onClick={handleDeleteSection} disabled={false}/>
            </div>
         </div>


         <div className="col-12 row">
            <div className="col-8">
               <div className="my-4" >
                  <InputWithLabel id={'section_name'} label={'Title'} type={'text'} value={sectionReducer.section_name} handleOnChange={handleOnChangeText} />
               </div>
               <div className="my-4">
                  <TextAreaWithLabel id={'description'} label={'Description'} type={'text'} input_height={'15vh'} value={sectionReducer.description} handleOnChange={handleOnChangeText} />
               </div>
               <div className="my-4">
                  <DropdownWithLabel
                     id={'content_layout'}
                     innerRef={layoutRef}
                     label={'Content Layout'}
                     disabled={true}
                     helpText={'This is default choice, more is coming soon'}
                     default_item={sectionReducer.content_layout ? contentLayoutChoices.find((el) => el.val === sectionReducer.content_layout).item : null}
                     dropdown_item={
                        contentLayoutChoices.map((cont, i) => {
                           return (
                              <Dropdown.Item key={i} onClick={() => handleOnSelect(layoutRef, cont.val)}>
                                 {cont.item}
                              </Dropdown.Item>
                           )
                        })
                     }
                  />
               </div>
               <div className="tw-flex tw-w-full tw-items-center my-4">
                  <div className={`tw-w-1/5 tw-font-medium tw-text-gray-800`}>Scale</div>

                  <div id={'using_scale'} ref={isUsingScaleRef} className="d-flex align-items-start" onChange={(e) => handleOnSelect(isUsingScaleRef, e.target.value)}>
                     <div className="form-check" style={{ cursor: 'pointer' }}>
                        <input
                           className="form-check-input"
                           type="radio"
                           name="isScale"
                           id="flexRadioDefault1"
                           checked={sectionReducer.using_scale == 1 ? true : false}
                           value={1}
                           style={{ cursor: 'pointer' }}
                           disabled={false}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault1" style={{ cursor: 'pointer' }}>
                           Yes
                        </label>
                     </div>

                     <div className="form-check ms-4" style={{ cursor: 'pointer' }}>
                        <input
                           className="form-check-input"
                           type="radio"
                           name="isScale"
                           id="flexRadioDefault2"
                           checked={sectionReducer.using_scale == 0 ? true : false}
                           value={0}
                           style={{ cursor: 'pointer' }}
                           disabled={false}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ cursor: 'pointer' }}>
                           No
                        </label>
                     </div>
                  </div>
               </div>
               {
                  sectionReducer.using_scale == 1 &&
                  (
                     <div>
                        <div className="">
                           {scaleLabel.content_choice.map((el, i) => {
                              return (

                                 <div className="border d-flex align-items-center px-4" style={{ width: "70%" }} key={i}>
                                    <div className="text-center">{i + 1}</div>
                                    <div className="my-2" style={{ width: '100%' }}><InputOnly id={'choice_text'} type={'text'} value={el.choice_text} handleOnChange={(e) => handleOnChangeText(e, el.scale_id)} disabled={false} /></div>
                                    <ButtonLink label="remove" onClick={() => handleDeleteScaleChoice(el.scale_id)} disabled={false} />
                                 </div>

                              )
                           })}
                           <div>
                              <ButtonLink label="Add option" onClick={handleAddScaleChoie} disabled={false} />
                           </div>
                        </div>
                     </div>
                  )
               }

            </div>

            <div className="col-4 my-4">
               <div className="d-flex justify-content-end">
                  <ButtonContent label="+ Content" onClick={() => setAddContentModal(true)} disabled={false} />
               </div>
            </div>


         </div>
         <ModalAddContentFrom
            show={addContentModal}
            onHide={() => setAddContentModal(false)}
            title={'Do you want to add content from ?'}
            handleOnClickNew={handleAddContent}
         />
      </div>
   )

}

export default SectionViewUpdate;

