/**
 * This part of code is made using Tailwind.css
 */


export function InputTextWithLabel({ id, autoComplete = true, label, handleOnChange, value, disabled, bg_input, style, label_width, input_width, placeholder, required = false }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <label className={`${label_width ? '' : 'tw-w-1/5'} tw-font-medium tw-text-gray-800 pe-4`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}{required && <span className="text-danger"> *</span>}</label>
         {autoComplete ?
            <input
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
               id={id} onChange={handleOnChange} value={value} type={"text"} disabled={disabled ? disabled : false} placeholder={placeholder} />
            :
            <input
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
               id={id} onChange={handleOnChange} value={value} type={"text"} autoComplete="off" disabled={disabled ? disabled : false} placeholder={placeholder} />}
      </div>
   )
}

export function InputWithLabelIntro({ id, autoComplete = true, label, handleOnChange, bg_input, value, type, style, disabled, label_width, input_width, required = false }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <label className={`${label_width ? '' : 'tw-w-1/5'} tw-font-medium tw-text-gray-800`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}{required && <span className="text-danger"> *</span>}</label>
         {autoComplete ?
            <input
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
               id={id} onChange={handleOnChange} value={value} type={type} disabled={disabled ? disabled : false} />
            :
            <input
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
               id={id} onChange={handleOnChange} value={value} type={type} autoComplete="off" disabled={disabled ? disabled : false} />}
      </div>
   )
}

export function InputOnly({ placeholder, id, type, disabled, handleOnChange, bg_input, value, style, input_width, is_invalid }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <input
            className={`${input_width ? '' : 'tw-w-4/5'} ${is_invalid ? 'tw-border-red-500 tw-ring-1 tw-ring-red-500' : 'focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                     focus:tw-outline-none tw-h-full`}
            style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
            id={id} onChange={handleOnChange} value={value} type={type}
            placeholder={placeholder}
            disabled={disabled ? disabled : false}
         />
      </div>
   )
}

export function InputWithLabel({ id, autoComplete = true, label, handleOnChange, bg_input, value, type, style, disabled, label_width, input_width, required = false }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <label className={`${label_width ? '' : 'tw-w-1/5'} tw-font-medium tw-text-gray-800`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}{required && <span className="text-danger"> *</span>}</label>
         {autoComplete ?
            <input
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
               id={id} onChange={handleOnChange} value={value} type={type} disabled={disabled ? disabled : false} />
            :
            <input
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
               id={id} onChange={handleOnChange} value={value} type={type} autoComplete="off" disabled={disabled ? disabled : false} />}
      </div>
   )
}

export function TextAreaOnly({ placeholder, id, type, label, handleOnChange, input_height, value, style, label_width, input_width }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <textarea
            className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                     focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
            style={input_width ? { width: input_width, height: input_height } : {}}
            id={id} onChange={handleOnChange} value={value} type={type}
            placeholder={placeholder}
         />
      </div>
   )
}

export function InputFile({ placeholder, id, accept, label, handleOnChange, value, style, label_width, input_width }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <input
            className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                     focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
            style={input_width ? { width: input_width } : {}}
            id={id} onChange={handleOnChange} value={value} type={'file'} accept={accept}
            placeholder={placeholder}
         />
      </div>
   )
}

export function TextAreaWithLabel({ id, autoComplete = true, label, handleOnChange, type, value, style, label_width, input_width, input_height, required = false, disabled }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <label className={`${label_width ? '' : 'tw-w-1/5'} tw-font-medium tw-text-gray-800 pe-4`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}{required && <span className="text-danger"> *</span>}</label>
         {autoComplete ?
            <textarea
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, height: input_height } : { height: input_height }}
               id={id} onChange={handleOnChange} value={value} type={type} disabled={disabled} />
            :
            <textarea
               className={`${input_width ? '' : 'tw-w-4/5'} tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                           focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500`}
               style={input_width ? { width: input_width, height: input_height } : { height: input_height }}
               id={id} onChange={handleOnChange} value={value} type={type} disabled={disabled} autoComplete="off" />}
      </div>
   )
}

export function InputWithLabelButton({ id, class_btn, style_btn, disabled, disabled_input, onChange_input, onClick_btn, type_input, value_input, label, placeholder_input, style, label_width, input_width, label_button, aria_label }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-relative" style={style}>
         <label className={`${label_width ? '' : 'tw-w-1/5'} tw-font-medium tw-text-gray-800`}
            style={label_width ? { width: label_width } : {}}
            htmlFor={id}>
            {label}
         </label>
         <div className={`input-group ${input_width ? '' : 'tw-w-4/5'}`}>
            <input
               type={type_input}
               className="form-control tw-rounded-l-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500 tw-pr-[20%]"
               placeholder={placeholder_input}
               aria-label={aria_label}
               aria-describedby="basic-addon2"
               onChange={onChange_input}
               value={value_input}
               id={id}
               disabled={disabled_input ? disabled_input : false}

            />
            <span className="input-group-text p-0 tw-rounded-r-lg" id="basic-addon2">
               <button className={`${class_btn} tw-rounded-r-lg`} disabled={disabled ? disabled : false} onClick={onClick_btn} style={style_btn}>{label_button}</button>
            </span>
         </div>
      </div >
   )

}

export function InputWithButton({ id, class_btn, style_btn, onChange_input, disabled_input, disabled, onClick_btn, type_input, value_input, placeholder_input, style, input_width, label_button, aria_label }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-relative" style={style}>
         <div className={`input-group ${input_width ? '' : 'tw-w-5/5'}`}>
            <input
               type={type_input}
               className="form-control tw-rounded-l-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 focus:tw-outline-none focus:tw-border-violet-500 focus:tw-ring-1 focus:tw-ring-violet-500 tw-pr-[20%]"
               placeholder={placeholder_input}
               aria-label={aria_label}
               aria-describedby="basic-addon2"
               onChange={onChange_input}
               value={value_input}
               id={id}
               disabled={disabled_input ? disabled_input : false}



            />
            <span className="input-group-text p-0 tw-rounded-r-lg" id="basic-addon2">
               <button className={`${class_btn} tw-rounded-r-lg`} disabled={disabled ? disabled : false} onClick={onClick_btn} style={style_btn}>{label_button}</button>
            </span>
         </div>
      </div >
   )

}

export function InformationWithLabel({ id, label, handleOnChange, bg_input, value, type, style, disabled, label_width, input_width }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <label className={`${label_width ? '' : 'tw-w-1/5'} tw-font-medium tw-text-gray-800`} htmlFor={id} style={label_width ? { width: label_width } : {}}>{label}</label>
         <div
            className={`${input_width ? '' : 'tw-w-4/5'} tw-p-1 tw-shadow-sm `}
            style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
            id={id}
         >
            {value != null && value != '' ? value : '-'}
         </div>
      </div>
   )
}

export function InputOnlyForDropdown({ placeholder, id, type, disabled, handleOnChange, bg_input, value, style, input_width }) {
   return (
      <div className="tw-flex tw-w-full tw-items-center tw-justify-center" style={style}>
         <input
            className={`${input_width ? '' : 'tw-w-4/5'}  tw-mx-2 tw-p-1 tw-shadow-sm tw-border tw-border-gray-300 
                     focus:tw-outline-none tw-h-full`}
            style={input_width ? { width: input_width, backgroundColor: bg_input } : { backgroundColor: bg_input }}
            id={id} onChange={handleOnChange} value={value} type={type}
            placeholder={placeholder}
            disabled={disabled ? disabled : false}
         />
      </div>
   )
}




