import { useEffect, useState } from "react";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../shared/component/button/Button";
import TabMonitoringView from "../tab/tab_monitoring/TabMonitoringView";
import TabReportView from "../tab/tab_report/TabReportView";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallbackPrompt } from "../../../shared/hook/useCallbackPrompt";
import screenAlert from "../../../shared/hook/SweetAlert";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";
import useUpdateMultiRaterView from "./useUpdateMultiraterView";
import TabSettingsUpdate from "../tab/tab_settings/TabSettingsUpdate";
import { CONSTANTS } from "../../../common/constants";
import TabContentUpdate from "../tab/tab_content/TabContentUpdate";
import TabParticipantUpdate from "../tab/tab_participant/TabParticipantUpdate";
import { useDeps } from "../../../shared/context/DependencyContext";
import { clearUpdateMultiraterRedux } from "../../../common/utils";
import screenToast from "../../../shared/hook/ScreenToast";
import LoadingBar from "react-top-loading-bar";
import TabPICProjectView from "../tab/tab_pic_project/TabPICProjectView";
import { REPORT_TYPE } from "../../report_template/constants";
import { Loading } from "../../../shared/component/loading/Loading";

function UpdateMultiraterView() {
    const location = useLocation();
    const navigate = useNavigate();
    const [surveyCode, setSurveyCode] = useState(location.state);
    const isEditing = useSelector((state) => state.update_is_editing);
    const tab = useSelector((state) => state.update_view_multi_rater);
    const dispatch = useDispatch();
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isEditing, CONSTANTS.ROUTER.UPDATE_MULTIRATER);
    const showAlert = screenAlert();
    const { multiRater, setting, sectionContent, scaleChoice, scoreWeight, participant, progress, setProgress,loading, ownershipList } = useUpdateMultiRaterView(surveyCode);
    const user = useSelector((state) => state.user);
    const { adminService, picService } = useDeps();
    const showToast = screenToast();
    const multiRaterReducer = useSelector((state) => state.update_multi_rater);
    const settingReducer = useSelector((state) => state.update_setting_multi_rater);
    const sectionReducer = useSelector((state) => state.update_section_multi_rater);
    const scoreReducer = useSelector((state) => state.update_score_multi_rater);
    const participantReducer = useSelector((state) => state.update_participant_multi_rater);
    const scaleReducer = useSelector((state) => state.update_scale_choice_multi_rater);
    const validReducer = useSelector((state) => state.update_valid_multi_rater);
    const reportUrlReducer = useSelector((state) => state.reportTemplateUrl);


    useEffect(() => {
        // console.log('SURVEY CODE', surveyCode)
    }, [surveyCode])

    // handler
    const setIsEditing = (val) => {
        dispatch(rootAction.updateMultiRater.setIsEditingAction(val));
    }

    const onChangeTab = (ev) => {
        dispatch(rootAction.updateMultiRater.setMRViewAction(ev.target.value));
    };

    const onUpdateMultiRater = async (e) => {
        e.preventDefault();

        if (!validReducer.setting || !validReducer.content || !validReducer.participant) {
            showAlert.warning({
                text: "You have error at:\n" +
                    (!validReducer.setting ? " [Settings] " : "") +
                    (!validReducer.content ? " [Content] " : "") +
                    (!validReducer.participant ? " [Participant] " : "") +
                    "\nPlease edit your input and try again.",
                buttons: {
                    cancel: {
                        text: "OK",
                        className: "bg-white text-dark"
                    }
                }
            })
        } else {
            showAlert.info({
                text: "Are you sure to save changes?",
                buttons: {
                    cancel: {
                        text: "Cancel",
                        className: "bg-white text-dark"
                    },
                    confirm: {
                        text: "Confirm",
                        value: true,
                        className: "bg-success"
                    }
                }
            }).then(value => {
                if (value) {
                    setIsEditing(false);

                    (async () => {
                        try {
                            let section = [...sectionReducer].map((sec) => {
                                let isUsingScale = false;

                                return {
                                    ...sec,
                                    content: [...sec.content].map((con) => {
                                        let choice = con.content_choice;

                                        if (con.content_type === 'scale' && con.score_same_as_scale === 1) {
                                            choice = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]
                                            isUsingScale = true
                                        } else if (con.content_type === 'scale' && con.score_same_as_scale === 0) {
                                            let reverse = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]

                                            choice = reverse.map((rev, i) => {
                                                return {
                                                    ...rev,
                                                    choice_score: reverse.length - i
                                                }
                                            });

                                            isUsingScale = true
                                        } else if (con.content_type === 'text_input') {
                                            choice = []
                                        }

                                        return {
                                            ...con,
                                            content_choice: choice
                                        }
                                    }),
                                    using_scale: !isUsingScale ? 0 : sec.using_scale,
                                    scale_range: !isUsingScale ? 0 : sec.scale_range
                                }
                            });

                            let multiRaterData = {
                                ...multiRaterReducer,
                                setting: { ...settingReducer },
                                participant: [...participantReducer],
                                section: section,
                                score_weight: { ...scoreReducer }
                            }

                            // console.log('data survey update', JSON.stringify(multiRaterData));
                            const response =
                                user.role == CONSTANTS.ROLE.ADMIN ? await adminService.multiRaterService.updateMultiRater(multiRaterData) :
                                    (user.role == CONSTANTS.ROLE.PIC ? await picService.multiRaterService.updateMultiRaterPICUrl(multiRaterData) : null);

                            if (!response) {
                                showToast.error("Something went wrong. Please try again later.");
                                setIsEditing(true);
                                return;
                            }

                            if (response.status == CONSTANTS.API.SUCCESS) {
                                clearUpdateMultiraterRedux(dispatch);
                                showToast.success(response.status);
                                //
                                if (tab == '5') {
                                    let data = {
                                        survey_code: reportUrlReducer.survey_code,
                                        created_by: user.email,
                                        reports: [],
                                    }
                                    if (reportUrlReducer.group_option == 2) {
                                        data.reports.push({
                                            type: REPORT_TYPE.MULTI_GROUP,
                                            tpl_code: reportUrlReducer.group_code,
                                            option: '2',
                                        });
                                    }
                                    if (reportUrlReducer.individu_option == 2) {
                                        data.reports.push({
                                            type: REPORT_TYPE.MULTI_INDIVIDU,
                                            tpl_code: reportUrlReducer.individu_code,
                                            option: '2',
                                        });
                                    }
                                    const resp = user.role == CONSTANTS.ROLE.ADMIN
                                        ? await adminService.reportTemplateService.updateSurveyReport(data)
                                        : await picService.reportTemplateService.updateSurveyReport(data);
                                    console.log(resp)
                                }
                                //
                                if (user.role == CONSTANTS.ROLE.ADMIN) {
                                    navigate(`${CONSTANTS.ROUTER.ADMIN_CMS}/${CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST}`)
                                } else if (user.role == CONSTANTS.ROLE.PIC) {
                                    navigate(`${CONSTANTS.ROUTER.PIC_CMS}/${CONSTANTS.ROUTER.MULTI_RATER_SURVEY_LIST}`)
                                }
                            } else {
                                showToast.error(response.status);
                                setIsEditing(true);
                            }
                        } catch (err) {
                            showToast.error("Something went wrong. Please try again later.");
                            setIsEditing(true);
                        }
                    })();
                }
            });
        }

    }

    // effect
    useEffect(() => {
        if (Object.keys(multiRaterReducer).length === 0 && multiRaterReducer.constructor === Object) {
            dispatch(rootAction.updateMultiRater.loadMRAction(multiRater));
            dispatch(rootAction.updateMultiRater.loadSettingMRAction(setting));
            dispatch(rootAction.updateMultiRater.loadSectionContentMRAction(sectionContent));
            dispatch(rootAction.updateMultiRater.loadScaleChoiceMRAction(scaleChoice));
            dispatch(rootAction.updateMultiRater.loadScoreMRAction(scoreWeight));
            dispatch(rootAction.updateMultiRater.loadParticipantMRAction(participant));
            dispatch(rootAction.updateMultiRater.listOwnershipMR(ownershipList))
        }
    }, [setting, multiRater, sectionContent, scaleChoice, scoreWeight, participant,ownershipList]);

    useEffect(() => {
        if (showPrompt) {
            showAlert.warning({
                text: "Are you sure to discard changes?",
                buttons: {
                    cancel: {
                        text: "Stay on page",
                        className: "bg-white text-dark"
                    },
                    confirm: {
                        text: "Leave page",
                        value: true,
                        className: "bg-primary"
                    }
                }
            }).then(value => {
                if (value) {
                    clearUpdateMultiraterRedux(dispatch);
                    confirmNavigation();
                } else {
                    cancelNavigation();
                }
            });
        }
    }, [showPrompt]);

    const handlePreviewPage = () => {
        let section = [...sectionReducer].map((sec) => {
            let isUsingScale = false;

            return {
                ...sec,
                content: [...sec.content].map((con) => {
                    let choice = con.content_choice;

                    if (con.content_type === 'scale' && con.score_same_as_scale === 1) {
                        choice = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]
                        isUsingScale = true
                    } else if (con.content_type === 'scale' && con.score_same_as_scale === 0) {
                        let reverse = [...scaleReducer.find((data) => data.section_id == sec.section_id).content_choice]

                        choice = reverse.map((rev, i) => {
                            return {
                                ...rev,
                                choice_score: reverse.length - i
                            }
                        });

                        isUsingScale = true
                    } else if (con.content_type === 'text_input') {
                        choice = []
                    }

                    return {
                        ...con,
                        content_choice: choice
                    }
                }),
                using_scale: !isUsingScale ? 0 : sec.using_scale,
                scale_range: !isUsingScale ? 0 : sec.scale_range
            }
        });

        let multiRaterData = {
            ...multiRaterReducer,
            setting: { ...settingReducer },
            participant: {
                add: [],
                delete: []
            },
            section: section,
            score_weight: { ...scoreReducer }
        }

        dispatch(rootAction.multiRater.setDataPreviewMR(multiRaterData))
        const currentUrl = window.location.pathname;
        navigate(`${currentUrl}/${CONSTANTS.ROUTER.PREVIEW_MULTIRATER}`)
    }

    return (
        <>
            <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />
            <div className="container tw-h-max">

                <div className='my-4 d-flex justify-content-between'>
                    <h4> Update Multi-Rater Project</h4>
                </div>
                <div className='my-4 d-flex justify-content-between align-items-center'>
                    {
                         surveyCode.ownership == 'explorer'  && user.role != 1 ?
                         <span className="fst-italic">You can only read the survey details below without being able to save changes.</span>
                        :
                        <span className="fst-italic">Update an existing survey, view survey progress from the Monitoring tab, or download a report from the Report tab.</span>

                    }
                    <div className="d-flex justify-content-end">
                        <div className=" me-2">
                            <ButtonOutlinePrimary label="Back" onClick={() => navigate(-1)} />
                        </div>

                        <div className=" me-2">
                            <ButtonOutlinePrimary label={'Preview'} onClick={handlePreviewPage} />
                        </div>

                        {
                            surveyCode.ownership == 'explorer' && user.role != 1 ?
                            <div>

                            </div>
                            :
                            <div className="">
                            <ButtonPrimary label="Save" onClick={onUpdateMultiRater} />
                        </div>
                        }
                        
                    </div>
                </div>

                <div className='card shadow' style={{ borderLeft: 0, borderBottom: 0, borderRight: 0, minHeight: '70vh' }}>

                    <div className="card-header d-flex p-0 bg-transparent border-0">
                        <div
                            className="btn-group w-100"
                            role="group"
                            aria-label="Basic radio toggle button group"

                        >
                            <input
                                type="radio"
                                className="btn-check"
                                name="btn-radio"
                                id="btn-settings"
                                autoComplete="off"
                                value={1}
                                defaultChecked={tab == 1 ? true : false}
                                onChange={onChangeTab}


                            />
                            <label className={`btn btn-outline-primary ${tab == 1 && 'btn-new-style'}`} htmlFor="btn-settings" style={{ width: '12vw', borderRadius: '0.5rem 0 0 0.5rem' }}>
                                Settings
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btn-radio"
                                id="btn-content"
                                autoComplete="off"
                                value={2}
                                defaultChecked={tab == 2 ? true : false}
                                onChange={onChangeTab}
                            />
                            <label className={`btn btn-outline-primary ${tab == 2 && 'btn-new-style'}`} htmlFor="btn-content" style={{ width: '12vw', borderRadius: 0 }}>
                                Content
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btn-radio"
                                id="btn-participant"
                                autoComplete="off"
                                value={3}
                                defaultChecked={tab == 3 ? true : false}
                                onChange={onChangeTab}
                            />
                            <label className={`btn btn-outline-primary ${tab == 3 && 'btn-new-style'}`} htmlFor="btn-participant" style={{ width: '12vw', borderRadius: 0 }}>
                                Participant
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btn-radio"
                                id="btn-monitoring"
                                autoComplete="off"
                                value={4}
                                defaultChecked={tab == 4 ? true : false}
                                onChange={onChangeTab}
                            />
                            <label className={`btn btn-outline-primary ${tab == 4 && 'btn-new-style'}`} htmlFor="btn-monitoring" style={{ width: '12vw', borderRadius: 0 }}>
                                Monitoring
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btn-radio"
                                id="btn-report"
                                autoComplete="off"
                                value={5}
                                defaultChecked={tab == 5 ? true : false}
                                onChange={onChangeTab}
                            />
                            <label className={`btn btn-outline-primary ${tab == 5 && 'btn-new-style'}`} htmlFor="btn-report" style={{ width: '12vw', borderRadius: 0 }}>
                                Report
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btn-radio"
                                id="btn-pic"
                                autoComplete="off"
                                value={6}
                                defaultChecked={tab == 6 ? true : false}
                                onChange={onChangeTab}
                            />
                            <label className={`btn btn-outline-primary ${tab == 6 && 'btn-new-style'}`} htmlFor="btn-pic" style={{ width: '12vw', borderRadius: '0 0.5rem 0.5rem 0' }}>
                                PIC
                            </label>
                        </div>
                    </div>

                    <div className="card-body p-4">
                        {tab == 1 ?
                            (
                                <TabSettingsUpdate setIsEditing={setIsEditing} />
                            )
                            :
                            (
                                tab == 2 ?
                                    (
                                        <TabContentUpdate setIsEditing={setIsEditing} />
                                    ) :
                                    (
                                        tab == 3 ?
                                            (
                                                <TabParticipantUpdate setIsEditing={setIsEditing} />
                                            ) :
                                            (
                                                tab == 4 ?
                                                    (
                                                        <TabMonitoringView survey_code={surveyCode.survey_code} />
                                                    ) :  (

                                                        tab == 5 ?
                                                        (
                                                            <TabReportView survey_code={surveyCode.survey_code} />
                                                        ) 
                                                        :
                                                        (
                                                            <TabPICProjectView />
                                                        )

                                                    )
                                            )

                                    )
                            )
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default UpdateMultiraterView;
