
import { IconButtonDelete } from '../../shared/component/button/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus } from '@fortawesome/free-solid-svg-icons';
// import { useEffect, useState } from 'react';
import { ButtonPrimary } from '../../shared/component/button/Button';
import ModalAddUser from '../../shared/component/modal/AddUserModal';
// import FilterModal from '../../shared/component/modal/FilterModal';
import { SearchColumn } from '../../shared/component/table_property/SearchColumn';
import { ShowEntries } from '../../shared/component/table_property/ShowEntries';
import usePICList from './usePICList';
import Pagination from '../../shared/component/table_property/Paginatination';
import LoadingBar from 'react-top-loading-bar';

function PicListView() {
 
  
  const {
    allPIC,
    handleChangeUserNIK,
    onCheckNIK,
    userName,
    userEmail,
    userNumber,
    userDep,
    userBU,
    handleChangeUserEmail,
    handleChangeUserNumber,
    modalAdd,
    setModalAdd,
    handleHideModal,
    nikFound,
    handleSavePIC,
    handleshowItem,
    showItem,
    onSearchData,
    handleInputSearchChange,
    searchText,
    handleDeletePIC,
    totalItem,
    pageNumber,
    setPageNumber,
    isloading,
    progress,
    setProgress
    

    } = usePICList();
    

  const paginate = (pageNumber) => {
      setPageNumber(pageNumber);
  };

  const previousPage =  () => {
        if (pageNumber != 1) {
          setPageNumber(pageNumber - 1);
          
        }
    };

    const nextPage = () => {
        if (pageNumber != Math.ceil(totalItem/ showItem)) {
          setPageNumber(pageNumber + 1);
        }
    };

  return (
    <>
       <LoadingBar color="#7749F8" progress={progress} onLoaderFinished={() => setProgress(0)} />
      <div className="container tw-h-max tw-max-w-[1400px]">
        <div className='m-4 d-flex justify-content-between'>
          <h4 className="me-4">PIC List</h4>
          
          <ButtonPrimary
                          label={
                              <div className="d-flex justify-content-center align-items-center">
                                  <FontAwesomeIcon icon={faPlus} size="xl"/>
                                  <div className="ml-1" style={{fontSize:'1 rem', fontWeight:'bold'}}>Add</div>
                              </div>
                          }
                          onClick={() => setModalAdd(true)}
                          
                      />
        
        </div>

        <div className='shadow p-4 rounded-3 bg-white'>
          <div className='row justify-content-between'>
            <div className='col-4 d-flex flex-row align-items-center'>
              <div>
                Show
              </div>

                  <ShowEntries onClick_item={handleshowItem}  default_item={showItem} />

              <div className=''>
                entries
              </div>
            </div>

            <div className='col-3 d-flex align-items-center'>
            
              <SearchColumn onChange_input={handleInputSearchChange}  onClick_btn={()=>onSearchData(showItem,1,searchText)} />
              
            </div>

          </div>

          <div className='mt-1 table-responsive'>
            <table className="table table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }} >No</th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Name</th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>NIK</th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Email</th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Number</th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Business Unit</th>
                  <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Department</th>
                  <th style={{ textAlign: 'center' }}>Delete</th>
                </tr>

              </thead>
              <tbody>
                { Array.isArray(allPIC) && allPIC.length != 0 ? (
                  allPIC.map((dt,i) => {
                    return (<tr key={i} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      {
                        pageNumber == 1 ? <td>{i+1}</td> : <td>{(i+1) + (showItem*(pageNumber-1))}</td>
                      }
                      <td>{dt.full_name}</td>
                      <td>{dt.nik}</td>
                      <td>{dt.email_platform}</td>
                      <td>{dt.phone_number}</td>
                      <td>{dt.bussines_unit_name}</td>
                      <td>{dt.department_name}</td>
                      <td>
                          <IconButtonDelete 
                          onClick={()=>handleDeletePIC(dt.nik,dt.full_name,dt.email_platform,dt.phone_number)}/>
                      </td>

                    </tr>)
                  })
                  
                )
              :
              (
                <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <td colSpan={8}>No data</td>
                </tr>
              )}
              
              </tbody>
            </table>
          </div>
          <Pagination showing={Array.isArray(allPIC) ? allPIC.length : 0} totalItem={totalItem} showItem={showItem} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>

        </div>
        <ModalAddUser
              show={modalAdd}
              title={`Add PIC`}
              onSave={handleSavePIC}
              onHide={handleHideModal}
              onChangeNIK={handleChangeUserNIK}
              onClick_CheckNIK={onCheckNIK}
              value_name={userName}
              value_bu={userBU}
              value_department={userDep}
              value_email={userEmail}
              value_number={userNumber}
              onChangeNumber={handleChangeUserNumber}
              onChangeEmail={handleChangeUserEmail}
              disableFromNIK={!nikFound}

            />

      </div>
    </>
    
  )
}

export default PicListView;


export const data = [
  {
    id: 1,
    name: 'Pradipta Megantara',
    nik: '11234567',
    email: 'pradiptamegantara@mail.com',
    bu: 'HR Corporate',
    department: 'People Capability Developement',
    number:'09868726492184',
    role:'explorer',
  },
  {
    id: 2,
    name: 'Muhammad Nugroho',
    nik: '12345672',
    email: 'muhnugrohofitrianto@mail.com',
    bu: 'Business Unit 12',
    department: 'Department 12',
    number:'09868726492184',
    role:'editor',

  },
  {
    id: 3,
    name: 'Nama 3',
    nik: '12345673',
    email: 'email3@mail.com',
    bu: 'Business Unit 13',
    department: 'Department 13',
    number:'09868726492184',
    role:'editor',

  },
  {
    id: 4,
    name: 'Nama 4',
    nik: '12345674',
    email: 'email4@mail.com',
    bu: 'Business Unit 14',
    department: 'Department 14',
    number:'09868726492184',
    role:'editor',


  },
  {
    id: 5,
    name: 'Nama 5',
    nik: '12345675',
    email: 'email5@mail.com',
    bu: 'Business Unit 15',
    department: 'Department 15',
    number:'09868726492184',
    role:'explorer',


  },
  {
    id: 6,
    name: 'Nama 1',
    nik: '11234567',
    email: 'email1@mail.com',
    bu: 'Business Unit 1',
    department: 'Department 1',
    number:'09868726492184',
    role:'explorer',


  },
  {
    id: 7,
    name: 'Nama 2',
    nik: '12345672',
    email: 'email2@mail.com',
    bu: 'Business Unit 12',
    department: 'Department 12',
    number:'09868726492184',
    role:'explorer',


  },
  {
    id: 8,
    name: 'Nama 3',
    nik: '12345673',
    email: 'email3@mail.com',
    bu: 'Business Unit 13',
    department: 'Department 13',
    number:'09868726492184',
    role:'explorer',


  },
  {
    id: 9,
    name: 'Nama 4',
    nik: '12345674',
    email: 'email4@mail.com',
    bu: 'Business Unit 14',
    department: 'Department 14',
    number:'09868726492184',
    role:'explorer',


  },
  {
    id: 10,
    name: 'Nama 5',
    nik: '12345675',
    email: 'email5@mail.com',
    bu: 'Business Unit 15',
    department: 'Department 15',
    number:'09868726492184',
    role:'explorer',


  },

]