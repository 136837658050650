export const CONSTANTS = {
  ROUTER: {
    // login
    LOGIN: "/login",
    LOGIN_AS_PARTICIPANT: "/login/participant",
    LOGIN_AS_ADMIN: "/login/admin",
    LOGIN_AS_PIC: "/login/pic",

    // parent path
    ADMIN_CMS: "/admin",
    PIC_CMS: "/pic",
    PARTICIPANT_DASHBOARD: "/participant",


      // child admin/pic
      ADMIN_LIST: 'admin-list',
      PIC_LIST: 'pic-list',
      CONFIG_DATA: 'config-data',
      ADMIN_SUPPORT: 'admin-support',
      SURVEY_HISTORY: 'survey-history',
      TARGET_PARTICIPANT_DATA: 'target-participant-data',
      QUESTION_BANK: 'question-bank',
      ADD_MULTI_RATER_SURVEY: 'add-multi-rater-survey',
      MULTI_RATER_SURVEY_LIST: 'multi-rater-survey-list',
      RATERS_HISTORY: 'raters-history',
      REPORT_TEMPLATE: 'report-template',
      TP_FROM_SAP: 'add-from-sap',
      TP_FROM_HISTORY: 'add-from-history',
      REVIEW_DATA_UPLOAD: 'add-upload-review-data',
      UPDATE_MULTIRATER: 'update-multi-rater',
      SELECT_QUESTION_HISTORY: "select-question-history",
      EDIT_RATERS_FOR_PARTICIPANT: "edit-raters",
      PREVIEW_MULTIRATER: "preview-multi-rater",
      ADD_SINGLE_RATER_SURVEY: 'add-single-rater-survey',
      SINGLE_RATER_SURVEY_LIST: 'single-rater-survey-list',
      EDIT_PARTICIPANT_SINGLE: "edit-participant",
      PREVIEW_SINGLERATER:'preview-single-rater',
      UPDATE_SINGLERATER: 'update-single-rater',
      DASHBOARD:'dashboard',

      ADD_REPORT_TEMPLATE: 'add-report-template',
      ADD_REPORT_SINGLE: 'add-report-single',
      EDIT_REPORT_SINGLE: 'edit-report-single',
      ADD_REPORT_MULTI_GROUP: 'add-report-multi-group',
      EDIT_REPORT_MULTI_GROUP: 'edit-report-multi-group',
      ADD_REPORT_MULTI: 'add-report-multi',
      EDIT_REPORT_MULTI: 'edit-report-multi',
      PREVIEW_TEMPLATE: 'preview-template',
      REPORT_PREVIEW_TEMPLATE: 'report-preview-template',


    // child participant
    PARTICIPANT_INTRODUCTION: "introduction",
    TASK: "task",
    PARTICIPANT_FAQ: "faq",
    PARTICIPANT_CONTACT_US: "contact-us",
    PARTICIPANT_ADD_RATERS: "add-raters",
    PARTICIPANT_MULTIRATER_LIST: "multi-rater-list",
    PARTICIPANT_SINGLERATER_LIST: "single-rater-list",
    PARTICIPANT_MULTIRATER_SURVEY_FORM: "multi-rater-form",
    PARTICIPANT_LIST_RATERS: "list-rater",
    PARTICIPANT_SINGLERATER_SURVEY_FORM: "single-rater-form",
    PARTICIPANT_MULTIRATER_SURVEY_FORM_AUTO: "link-multi-rater-form",
    PARTICIPANT_SINGLERATER_SURVEY_FORM_AUTO: "link-single-rater-form",


  },

  USER: {
    PARTICIPANT: "Participant",
    PIC: "PIC",
    ADMIN: "Administrator",
  },

  API: {
    SUCCESS: "SUCCESS",
    SUCCESS_MSG: "",
    WRONG_OTP: "WRONG OTP",
    OTP_EXPIRED: "OTP EXPIRED",
  },

   CONTENT_TYPE: {
      SINGLE_CHOICE: 'single_choice',
      MULTIPLE_CHOICE: 'multiple_choice',
      TEXT_INPUT: 'text_input',
      SCALE: 'scale',
      RANKING: 'ranking'
   },


   SURVEY_FORM_REDUX: {
      PARTICIPANT_NIK: "participant_nik",
      PARTICIPANT_BU: "participant_bu",
      PARTICIPANT_DEPT: "participant_dept",
      PARTICIPANT_EMAIL: "participant_email",
      PARTICIPANT_ID:"s_raters_id",
      TARGET_ID:"s_participant_id",
      TARGET_NIK: "target_nik",
      TARGET_EMAIL: "target_email",
      SURVEY_CODE: "survey_code",
      SURVEY_NAME: "survey_name",
      SECTION_CODE: "section_code",
      SECTION_NAME: "section_name",
      CONTENT_CODE: "content_code",
      CONTENT_NAME: "content_name",
      CONTENT_TYPE: "content_type",
      CONTENT_SCORE: "content_score",
      CHOICE_CODE: "choice_code",
      CHOICE_TEXT: "choice_text",
      CHOICE_SCORE: "choice_score",
      SCALE_DISPLAY: "scale_display",
      IS_MANDATORY: "is_mandatory",
      ANSWER: "answer",
      GOAL_ID: "goal_id",
      CREATED_AT: "created_at",
   },

  ROLE: {
    ADMIN: 1,
    PIC: 2,
    PARTICIPANT: 3,
  },

  ROLE_IN_SURVEY_ID: {
    SELF_ID: 1,
    SUPERVISOR_ID: 2,
    SUBORDINATE_ID: 3,
    PEER_ID: 4,
    STAKEHOLDER_ID: 5,
    OTHER_ID: 6,
  },
};
