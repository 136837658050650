import { useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../common/constants";

function useTargetParticipantHistory() {
    const {adminService,picService} = useDeps();
    const [allTPHistory, setAllTPHistory] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filterBu, setFilterBu] = useState('');
    const [filterDep, setFilterDep] = useState('');
    const [filterLevel, setFilterLevel] = useState('');
    const [limit, setLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItem,setTotalItem] = useState(0);
    const [progress, setProgress] = useState(0);
    const user = useSelector((state) => state.user);


    useEffect(() => {
        if (pageNumber == 1) {
                onGetAllTPHistoryData(filterBu,filterDep,filterLevel,searchText,limit,pageNumber);
        }
        setPageNumber(1);

    }, [limit])


    useEffect(() => {
        onGetAllTPHistoryData(filterBu,filterDep,filterLevel,searchText,limit,pageNumber);
    }, [pageNumber])

    const onGetAllTPHistoryData = async (business_unit_name,deparment_name,level,search,limit,page_number) => {
        setProgress(10);
        try {
            setProgress(30);
            if (user.role == CONSTANTS.ROLE.ADMIN) {
                const response = await adminService.targetParticipantHistoryService.getHistoryTargetParticipantData(business_unit_name,deparment_name,level,search,limit,page_number);
                setProgress(60);
                // console.log(response);

                if (response.status == 'SUCCESS' && response.data.data.length != 0) {
                    setAllTPHistory(response.data.data);
                    setTotalItem(response.data.total_item);
                    // console.log(response.data.data);
                }
            }else {
                const response = await picService.targetParticipantHistoryService.getHistoryTargetParticipantDataPICUrl(business_unit_name,deparment_name,level,search,limit,page_number);
                setProgress(60);
                // console.log(response);

                if (response.status == 'SUCCESS' && response.data.data.length != 0) {
                    setAllTPHistory(response.data.data);
                    setTotalItem(response.data.total_item);
                    
                }
            }
            
        } catch (error) {
            console.log(error)
        } finally {
            // console.log(`finish`);
            setProgress(100);
        }
    }

    const handleInputSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleSearchData = () => {
        if (pageNumber == 1) {
            onGetAllTPHistoryData(filterBu,filterDep,filterLevel,searchText,limit,pageNumber)
        } else {
            setPageNumber(1);
        }
    }


    return{
        onGetAllTPHistoryData,
        allTPHistory,
        pageNumber,
        setSearchText,
        searchText,
        setPageNumber,
        filterBu,
        filterDep,
        filterLevel,
        setLimit,
        setFilterBu,
        setFilterDep,
        setFilterLevel,
        limit,
        totalItem,
        handleInputSearchChange,
        handleSearchData,
        progress,
        setProgress
        
    }

}

export default useTargetParticipantHistory;

