import { IconButtonDelete, IconButtonEdit } from "../../../../shared/component/button/IconButton";
import { InputWithButton } from "../../../../shared/component/input/Input";
import { Dropdown } from "react-bootstrap";
import { DropdownButtonOnly } from "../../../../shared/component/dropdown/DropdownInput";
import { useEffect, useState } from "react";
import { ModalUploadFile } from "../../../target_participant/add_target_participant/tp_upload_file/ModalUploadFile";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../../redux/RootAction";
import { roleInSurvey } from "./participantData";
import screenAlert from "../../../../shared/hook/SweetAlert";
import useTabParticipant from "./useTabParticipant";
import { openSpreadsheetImport, closeSpreadsheetImport, setImportedData, validateSpreadsheetData } from "../../../../redux/SpreadSheet/SpreadSheetAction";
import ImportFile from "../../../target_participant/add_target_participant/tp_upload_file/tab_upload/ImportFile";
import { Table } from 'react-bootstrap';

function TabParticipant() {
    const [openImport, setOpenImport] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const navigate = useNavigate();
    const scoreReducer = useSelector((state) => state.score_multi_rater);
    const participantReducer = useSelector((state) => state.participant_multi_rater);
    const dispatch = useDispatch();
    const showAlert = screenAlert();
    const [nikBulk, setNikBulk] = useState([]);
    const {  emailLoginAsParticipant } = useTabParticipant({ nik: nikBulk });
    const isOpen = useSelector((state) => state.spreadsheet.isOpen);
    const importedData = useSelector((state) => state.spreadsheet.importedData);
    const [participantData, setParticipantData] = useState([]);


    
    useEffect(() => {
        dispatch(rootAction.multiRater.setValidParticipantMRAction(true));
        // console.log('PARTICIPANT REDUCER', participantReducer);
        
    }, []);

    useEffect(() => {
        if (Array.isArray(participantReducer)) {
            let nik = [];
            participantReducer.map((par) => {
                if (par.nik) nik.push(par.nik);
                if (par.raters) {
                    par.raters.map((rat) => {
                        if (rat.nik) nik.push(rat.nik);
                    })
                }
                if (!par.email) dispatch(rootAction.multiRater.setValidParticipantMRAction(false));
            });

            setNikBulk(nik);
        }
        // console.log('PARTICIPANT REDUCER', participantReducer);
    }, [participantReducer]);

    // useEffect(() => {
    //     let isValid = true;
    //     participantReducer.length > 0 && participantReducer.map((dt) => {
    //         if (Array.isArray(emailLoginAsParticipant) && emailLoginAsParticipant.length > 0) {
    //             let email = emailLoginAsParticipant.find((val) => val.nik == dt.nik)?.email;
    //             if (!dt.email) {
    //                 isValid = false;
    //                 dispatch(rootAction.multiRater.setValidParticipantMRAction(false));
    //                 return
    //             }
    //             if (email && email.toLowerCase() != dt.email.toLowerCase()) {
    //                 isValid = false;
    //                 dispatch(rootAction.multiRater.setValidParticipantMRAction(false));
    //                 return
    //             }
    //         }

    //         dt.raters.map((rat, j) => {
    //             if (j > 0 && Array.isArray(emailLoginAsParticipant) && emailLoginAsParticipant.length > 0) {
    //                 let email = emailLoginAsParticipant.find((val) => val.nik == rat.nik)?.email;
    //                 if (!rat.email) {
    //                     isValid = false;
    //                     dispatch(rootAction.multiRater.setValidParticipantMRAction(false));
    //                     return
    //                 }
    //                 if (email && email.toLowerCase() != rat.email.toLowerCase()) {
    //                     isValid = false;
    //                     dispatch(rootAction.multiRater.setValidParticipantMRAction(false));
    //                     return
    //                 }
    //             }
    //         });
    //     });

    //     if (isValid) dispatch(rootAction.multiRater.setValidParticipantMRAction(true));
    // }, [emailLoginAsParticipant]);

    
    const addTargetParticipantButton = [
        {
            label: 'From SAP',
            action: () => navigate(`${CONSTANTS.ROUTER.TP_FROM_SAP}`),
            isDisabled: false
        },
        {
            label: 'From History',
            action: () => {
                navigate(`${CONSTANTS.ROUTER.TP_FROM_HISTORY}`)
            },
            isDisabled: false
        },
        {
            label: 'Upload File',
            action: () => {
                
                // console.log("Upload File button clicked");
                setOpenImport(true)
                // handleOpenImport();
                // dispatch(handleOpenImport());
                // isOpen(true)
            },
            isDisabled: false

        }
    ]

    const handleOnChangeText = (e) => {
        dispatch(rootAction.multiRater.setScoreMRAction({
            field: e.target.id,
            payload: e.target.value
        }));
    }

    const handleOnEditParticipant = (e, val) => {
        // if nik ada
        if (val.nik == null || val.nik == undefined || val.nik == '') {
            dispatch(rootAction.multiRater.setEditParticipantViewByEmail(val.email));
        } else {
            dispatch(rootAction.multiRater.setEditParticipantView(val.nik));
        }
        navigate(`${CONSTANTS.ROUTER.EDIT_RATERS_FOR_PARTICIPANT}`);
    }

    const handleOnDeleteParticipant = (e, val) => {
        showAlert.warning({
            title: "Warning",
            text: "Are you sure to remove \"" + val.full_name + "\"?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Delete",
                    className: "bg-danger",
                    value: true
                }
            },
            isDanger: true
        }).then(value => {
            if (value) {
                dispatch(rootAction.multiRater.deleteParticipantMRAction(val.nik));
            }
        });
    }
    const handleOpenImport = () => {
        dispatch(openSpreadsheetImport());
    };

    const handleCloseImport = () => setOpenImport(false);

    // const handleImportedData = (data) => {
    //     // Process imported data here
    //     dispatch(rootAction.singleRater.setImportedData(data));
    //     handleCloseImport();
    // };


      const normalizeData = (data) => {
        return {
            nik: data.nik || data.nik,
            full_name: data.name || data.full_name,
            email: data.email,
            phone_number: data.phone_number || data.no_hp,
            level_user: data.level_user || data.level,
            position_user: data.position_user || data.position,
            business_unit_name: data.business_unit_name,
            deparment_name: data.deparment_name,
            role_in_survey_id: 1,
            is_internal: data.is_internal !== undefined ? data.is_internal : (data.participant === 'Internal' ? 1 : 0),
            is_active: data.is_active !== undefined ? data.is_active : 1,
            dob: data.dob,
            gender: data.gender,
            join_date: data.join_date,
            participant: data.participant
        };
    };

    const handleImportData = (validData) => {
        // console.log('validData:', validData); // Debug log
        if (!validData || validData.length === 0) {
            console.error('No valid data found'); // Debug log
            return;
        };
        const normalizedData = validData.map(normalizeData);
        // console.log("dari valid data Import ", validData);
        const newParticipants = normalizedData.map((data) => ({
            
            nik: data.nik != null ? data.nik : '',
            full_name: data.full_name != null ? data.full_name : '',
            email: data.email != null ? data.email : '',
            phone_number: data.phone_number != null ? data.phone_number : '',
            level: data.level_user != null ? data.level_user : '',
            position: data.position_user != null ? data.position_user : '',
            business_unit_name: data.business_unit_name != null ? data.business_unit_name : '',
            deparment_name: data.deparment_name != null ? data.deparment_name : '',
            role_in_survey_id: 1,
            is_internal: data.is_internal != null ? data.is_internal : '',
            is_active: 1,
            raters: [
                {
                    nik: data.nik != null ? data.nik : '',
                    full_name: data.full_name != null ? data.full_name : '',
                    email: data.email != null ? data.email : '',
                    phone_number: data.phone_number != null ? data.phone_number : '',
                    level: data.level_user != null ? data.level_user : '',
                    position: data.position_user != null ? data.position_user : '',
                    business_unit_name: data.business_unit_name != null ? data.business_unit_name : '',
                    deparment_name: data.deparment_name != null ? data.deparment_name : '',
                    role_in_survey_id: 1,
                    dob: data.dob != '' ? data.dob : null,
                    gender: data.gender == 'LAKI-LAKI' || data.gender == 'PEREMPUAN' ? data.gender : '',
                    join_date: data.join_date != '' ? data.join_date : null,
                    is_internal: data.is_internal != null ? data.is_internal : '',
                    is_active: 1,
                    status: 'not started'
                }
            ]
        }));
        // console.log('newParticipants:', newParticipants); // Debug log
        setParticipantData((prevData) => {
            const updatedData = [...prevData, ...newParticipants];
            // console.log('updatedData:', updatedData); // Debug log
            return updatedData;
        });

        // console.log('updatedData Participant:', participantData)
        handleCloseImport();
        // Automatically submit participant data after import
        handleOnSubmitParticipant(newParticipants);
    };
    const handleOnSubmitParticipant = (importedData=[]) => {
        const dataToSubmit = [...participantData, ...importedData];
        // console.log('participant data di add from upload file', participantData);
        showAlert.info({
            text: "Are you sure to save participants for survey?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            const dataSubmitAndCurrentData = [...dataToSubmit,...participantReducer]
            if (value) {
                dispatch(rootAction.multiRater.setParticipantMRAction(dataSubmitAndCurrentData));


            }
        });
    };

    
    return (
        <div className="col-12">
            <div className="col-12 pb-4 d-flex align-items-center" style={{ borderBottom: '0.1rem solid black' }} >
                <div className="col-2">
                    <h5 className="m-0">Score Weight</h5>
                </div>

                <div className="col-10 d-flex" >
                    <div className="col-2 ps-4">
                        <div className="me-2  mb-1">
                            Supervisor :
                        </div>
                        <div className="" >
                            <InputWithButton id={'supervisor_weight'} label_button={'%'} type_input={'text'} disabled={true} class_btn={'btn'} style_btn={{ border: 0, color: 'black', fontWeight: 'bold' }} value_input={scoreReducer.supervisor_weight} onChange_input={handleOnChangeText} />
                        </div>
                    </div>

                    <div className="col-2 ps-4" >

                        <div className="me-2 mb-1">
                            Peer  :
                        </div>
                        <div className="">
                            <InputWithButton id={'peer_weight'} label_button={'%'} type_input={'text'} disabled={true} class_btn={'btn'} style_btn={{ border: 0, color: 'black', fontWeight: 'bold' }} value_input={scoreReducer.peer_weight} onChange_input={handleOnChangeText} />
                        </div>
                    </div>

                    <div className="col-2 ps-4" >

                        <div className="me-2  mb-1">
                            StakeHolder :
                        </div>
                        <div className="" >
                            <InputWithButton id={'stakeholder_weight'} label_button={'%'} type_input={'text'} disabled={true} class_btn={'btn'} style_btn={{ border: 0, color: 'black', fontWeight: 'bold' }} value_input={scoreReducer.stakeholder_weight} onChange_input={handleOnChangeText} />
                        </div>
                    </div>

                    <div className="col-2 ps-4" >

                        <div className="me-2 mb-1">
                            Subordinate :
                        </div>
                        <div className="" >
                            <InputWithButton id={'subordinate_weight'} label_button={'%'} type_input={'text'} disabled={true} class_btn={'btn'} style_btn={{ border: 0, color: 'black', fontWeight: 'bold' }} value_input={scoreReducer.subordinate_weight} onChange_input={handleOnChangeText} />
                        </div>
                    </div>

                    <div className="col-2 ps-4" >

                        <div className="me-2 mb-1">
                            Self :
                        </div>
                        <div className="" >
                            <InputWithButton id={'self_weight'} label_button={'%'} type_input={'text'} disabled={true} class_btn={'btn'} style_btn={{ border: 0, color: 'black', fontWeight: 'bold' }} value_input={scoreReducer.self_weight} onChange_input={handleOnChangeText} />
                        </div>
                    </div>
                </div>
            </div>



            <div className="">
                <div className='d-flex justify-content-between my-4' >
                    <div className="tw-self-center">
                        <h5 className="m-0">Participant & Raters List</h5>
                    </div>
                    <DropdownButtonOnly
                        dropdown_item={
                            addTargetParticipantButton.map((addP, i) => {
                                return (
                                    <Dropdown.Item
                                        key={i} style={{ border: '1px solid white' }}
                                        onClick={addP.action}
                                        disabled={addP.isDisabled}
                                    >
                                        {addP.label}</Dropdown.Item>
                                )
                            })
                        }
                        label={"ADD"}
                    />
                </div>

                <div className="">
                    <div className='table-responsive'>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }} >No</th>
                                    <th rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Target Participant Details</th>

                                    <th colSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Raters</th>
                                    <th colSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>Actions</th>
                                </tr>

                                <tr>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Relationship</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Details</th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Edit </th>
                                    <th style={{ verticalAlign: 'middle', textAlign: 'center' }}>Delete</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    participantReducer.length > 0 ? participantReducer.map((dt, i) => {
                                        let isValidParticipant = true;
                                        if (Array.isArray(emailLoginAsParticipant) && emailLoginAsParticipant.length > 0) {
                                            let email = emailLoginAsParticipant.find((val) => val.nik == dt.nik)?.email;
                                            if (email && email.toLowerCase() != dt.email.toLowerCase()) {
                                                isValidParticipant = false;
                                            }
                                        }

                                        if (!dt.email) {
                                            isValidParticipant = false;
                                        }

                                        return (
                                            <>
                                                <tr>
                                                    <td rowSpan={dt.raters.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}>{i + 1}</td>
                                                    <td rowSpan={dt.raters.length} style={{ verticalAlign: 'middle' }} className={!isValidParticipant && "text-danger"}>
                                                        <span className="tw-font-medium">{dt.full_name}</span>
                                                        <br></br>
                                                        {dt.email ? dt.email.toLowerCase() : "-"}
                                                    </td>
                                                    <td style={{ textAlign: 'center', verticalAlign: 'middle', }}>{roleInSurvey.find((el) => el.id == dt.raters[0].role_in_survey_id).role_name}</td>
                                                    <td style={{ verticalAlign: 'middle' }} className={!isValidParticipant && "text-danger"}>
                                                        <span className="tw-font-medium">{dt.raters[0].full_name}</span>
                                                        <br></br>
                                                        {dt.raters[0].email ? dt.raters[0].email.toLowerCase() : "-"}
                                                    </td>

                                                    <td rowSpan={dt.raters.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}><IconButtonEdit onClick={(e) => handleOnEditParticipant(e, dt)} /></td>
                                                    <td rowSpan={dt.raters.length} style={{ textAlign: 'center', verticalAlign: 'middle' }}><IconButtonDelete onClick={(e) => handleOnDeleteParticipant(e, dt)} /></td>
                                                </tr>
                                                {
                                                    dt.raters.map((rat, j) => {
                                                        if (j > 0) {
                                                            let isValidRater = true;
                                                            if (Array.isArray(emailLoginAsParticipant) && emailLoginAsParticipant.length > 0) {
                                                                let email = emailLoginAsParticipant.find((val) => val.nik == rat.nik)?.email;
                                                                if (email && email.toLowerCase() != rat.email.toLowerCase()) {
                                                                    isValidRater = false;
                                                                }
                                                            }

                                                            if (!rat.email) {
                                                                isValidRater = false;
                                                            }

                                                            return (
                                                                <tr key={j} >
                                                                    <td style={{ textAlign: 'center', verticalAlign: 'middle', }}>{roleInSurvey.find((el) => el.id == rat.role_in_survey_id).role_name}</td>
                                                                    <td style={{ verticalAlign: 'middle' }} className={!isValidRater && "text-danger"}>
                                                                        <span className="tw-font-medium">{rat.full_name}</span>
                                                                        <br></br>
                                                                        {rat.email ? rat.email.toLowerCase() : "-"}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </>
                                        )
                                    }) : <tr><td colSpan={8} className="text-center">No Data</td></tr>
                                }
                            </tbody>

                        </table>
                    </div>

                </div>
                <ImportFile
                    isOpen={openImport}
                    onClose={handleCloseImport}
                    onSubmit={handleImportData}
                />

            </div>
        </div>
    )
}

export default TabParticipant;