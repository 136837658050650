import { Col, Dropdown, Row } from "react-bootstrap";
import { CONSTANTS } from "../../../common/constants";
import { TextAreaOnly } from "../input/Input";
import { DropdownOnly, DropdownWithLabel } from "../dropdown/DropdownInput";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";



export const AnswerCard = ({ sectionCode, sectionName, widthWindow, content = contentTemplate, oldContent = [] }) => {
    const surveyDataReducer = useSelector((state) => state.surveyFormData);
    const manyAnswerReducer = useSelector((state) => state.insertManyAnswer);
    const dispatch = useDispatch();
    const [change, setChange] = useState(false);

    const handleEditFieldManyAnswer = (section_code, content_code, field, payload) => {
        // console.log(`handleEditFieldManyAnswer`)
        dispatch(rootAction.surveyForm.editManyAnswer({
            key_section: section_code,
            key_content: content_code,
            field: field,
            payload: payload
        }));
    }

    const handleEditFieldManyAnswerSameContent = (section_code, content_code,choice_code, field, payload) => {
        // console.log(`handleEditFieldManyAnswer`)
        dispatch(rootAction.surveyForm.editManyAnswerSameContent({
            key_section: section_code,
            key_content: content_code,
            key_choice: choice_code,
            field: field,
            payload: payload
        }));
    }

    const handleDeleteManyAnswer = (section_code, content_code, choice_code) => {
        // console.log(`handleDeleteManyAnswer`)
        dispatch(rootAction.surveyForm.deleteManyAnswer({
            key_section: section_code,
            key_content: content_code,
            key_choice: choice_code,
        }));
    }

    const deleteMandatoryCheck = (section_code,content_code) => {
        dispatch(rootAction.surveyForm.deleteMandatoryContent(
            {
                section_code: section_code,
                content_code: content_code,
            }
        ))
    }


    const handleAddEditChoicesAnswer = (
        sectionCode,
        sectionName,
        contentCode,
        contentName,
        contentType,
        choiceCode,
        choiceText,
        choiceScore,
        goalId,
    isMandatory) => {
        const date = new Date();
        // console.log(`handleAddEdit ${choiceCode}`)
        let oneAnswer = {
            participant_nik: surveyDataReducer.participant_nik,
            participant_bu: surveyDataReducer.participant_bu == null ? "" : surveyDataReducer.participant_bu,
            participant_dept: surveyDataReducer.participant_dept == null ? "" : surveyDataReducer.participant_dept ,
            participant_email: surveyDataReducer.participant_email,
            s_raters_id: surveyDataReducer.s_raters_id,
            s_participant_id: surveyDataReducer.s_participant_id,
            target_nik: surveyDataReducer.target_nik,
            target_email: surveyDataReducer.target_email,
            survey_code: surveyDataReducer.survey_code,
            survey_name: surveyDataReducer.survey_name,
            section_code: sectionCode,
            section_name: sectionName,
            content_code: contentCode,
            content_name: contentName,
            content_type: contentType,
            choice_code: choiceCode,
            choice_text: choiceText,
            choice_score: choiceScore,
            scale_display: "",
            is_mandatory: isMandatory == 1 ? "true" : "false",
            answer: "",
            goal_id: goalId,
            created_at: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        }

        const dt = manyAnswerReducer.filter(e => e.section_code == oneAnswer.section_code && e.content_code == oneAnswer.content_code)
        if (dt.length == 0) {
            dispatch(rootAction.surveyForm.setManyAnswer(oneAnswer));
            if (isMandatory == 1) {
                deleteMandatoryCheck(oneAnswer.section_code,oneAnswer.content_code)
            }
        } else {
            handleEditFieldManyAnswer(sectionCode, contentCode, CONSTANTS.SURVEY_FORM_REDUX.CHOICE_CODE, choiceCode);
            handleEditFieldManyAnswer(sectionCode, contentCode, CONSTANTS.SURVEY_FORM_REDUX.CHOICE_TEXT, choiceText);
            handleEditFieldManyAnswer(sectionCode, contentCode, CONSTANTS.SURVEY_FORM_REDUX.CHOICE_SCORE, choiceScore);
            if (isMandatory == 1) {
                deleteMandatoryCheck(oneAnswer.section_code,oneAnswer.content_code)
            }
        }

        setChange(!change);
    }

    const handleAddAnswerInput = (e) => {
        const date = new Date();
        const answerText = e.target.value;
        let oneAnswer = {
            participant_nik: surveyDataReducer.participant_nik,
            participant_bu: surveyDataReducer.participant_bu == null ? "" :  surveyDataReducer.participant_bu,
            participant_dept: surveyDataReducer.participant_dept == null ? "" : surveyDataReducer.participant_dept ,
            participant_email: surveyDataReducer.participant_email,
            s_raters_id: surveyDataReducer.s_raters_id,
            s_participant_id: surveyDataReducer.s_participant_id,
            target_nik: surveyDataReducer.target_nik,
            target_email: surveyDataReducer.target_email,
            survey_code: surveyDataReducer.survey_code,
            survey_name: surveyDataReducer.survey_name,
            section_code: sectionCode,
            section_name: sectionName,
            content_code: content.content_code,
            content_name: content.content_name,
            content_type: content.content_type,
            choice_code: "",
            choice_text: "",
            choice_score: 0,
            scale_display: "",
            is_mandatory: content.is_mandatory == 1 ? "true": "false",
            answer: answerText,
            goal_id: content.goal_id,
            created_at: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        }

        const dt = manyAnswerReducer.filter(e => e.section_code == oneAnswer.section_code && e.content_code == oneAnswer.content_code)
        if (dt.length == 0) {
            // console.log('TDK ADA YG SAMA')
            if (answerText.length == 1) {
                dispatch(rootAction.surveyForm.setManyAnswer(oneAnswer));
            }
            if (content.is_mandatory == 1 && answerText != "") {
                deleteMandatoryCheck(sectionCode,content.content_code)
            }
        } else {
            // console.log('ADA YG SAMA')
            handleEditFieldManyAnswer(sectionCode, content.content_code, CONSTANTS.SURVEY_FORM_REDUX.ANSWER, answerText);
            if (content.is_mandatory == 1 && answerText == "") {
                dispatch(rootAction.surveyForm.addContentMandatory(
                    {
                        section_code :sectionCode,
                        content_code:content.content_code
                    }
                ))
            }else if (content.is_mandatory == 1 && answerText != "") {
                deleteMandatoryCheck(sectionCode,content.content_code)
            }

        }
        setChange(!change);
        // 
    }

    const arr = [
        { id: 1, name: 'A' },
        { id: 2, name: 'A' },
        { id: 2, name: 'B' },
        { id: 3, name: 'B' },
        { id: 4, name: 'C' }
    ]
    
    const handleMultipleChoiceAnswer = (
        sectionCode,
        sectionName,
        contentCode,
        contentName,
        contentType,
        choiceCode,
        choiceText,
        choiceScore,
        goalId,
    isMandatory) => {
        const date = new Date();
        // console.log(`handleAddEdit ${choiceCode}`)
        let oneAnswer = {
            participant_nik: surveyDataReducer.participant_nik,
            participant_bu: surveyDataReducer.participant_bu == null ? "" : surveyDataReducer.participant_bu,
            participant_dept: surveyDataReducer.participant_dept == null ? "" : surveyDataReducer.participant_dept ,
            participant_email: surveyDataReducer.participant_email,
            s_raters_id: surveyDataReducer.s_raters_id,
            s_participant_id: surveyDataReducer.s_participant_id,
            target_nik: surveyDataReducer.target_nik,
            target_email: surveyDataReducer.target_email,
            survey_code: surveyDataReducer.survey_code,
            survey_name: surveyDataReducer.survey_name,
            section_code: sectionCode,
            section_name: sectionName,
            content_code: contentCode,
            content_name: contentName,
            content_type: contentType,
            choice_code: choiceCode,
            choice_text: choiceText,
            choice_score: choiceScore,
            scale_display: "",
            is_mandatory: isMandatory == 1 ? "true" : "false",
            answer: "",
            goal_id: goalId,
            created_at: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        }

        // console.log(oneAnswer);
        const dt = manyAnswerReducer.filter(e => e.section_code == oneAnswer.section_code && e.content_code == oneAnswer.content_code && e.choice_code == oneAnswer.choice_code)
        if (dt.length == 0) {
            // console.log('BELUM PERNAH DI PILIH => set ke redux')
            dispatch(rootAction.surveyForm.setManyAnswer(oneAnswer));
           if (isMandatory == 1) {
            deleteMandatoryCheck(sectionCode,contentCode)
           }

        } else {
            // console.log('SUDAH PERNAH DI PILIH => hapus dr redux')
            handleDeleteManyAnswer(sectionCode, contentCode, choiceCode);
            if (isMandatory == 1) {
             dispatch(rootAction.surveyForm.addContentMandatory(
                {
                    section_code :sectionCode,
                    content_code:contentCode
                }
            ))
                
            }
        }
        
        setChange(!change);
    }

    const handleAddEditRankingAnswer = (
        sectionCode,
        sectionName,
        contentCode,
        contentName,
        contentType,
        choiceCode,
        choiceText,
        choiceScore,
        goalId,
    isMandatory) => {
        const date = new Date();
        // console.log(`handleAddEditRankingAnswer ${choiceCode}`)
        let oneAnswer = {
            participant_nik: surveyDataReducer.participant_nik,
            participant_bu: surveyDataReducer.participant_bu == null ? "" :  surveyDataReducer.participant_bu,
            participant_dept: surveyDataReducer.participant_dept == null ? "" : surveyDataReducer.participant_dept ,
            participant_email: surveyDataReducer.participant_email,
            s_raters_id: surveyDataReducer.s_raters_id,
            s_participant_id: surveyDataReducer.s_participant_id,
            target_nik: surveyDataReducer.target_nik,
            target_email: surveyDataReducer.target_email,
            survey_code: surveyDataReducer.survey_code,
            survey_name: surveyDataReducer.survey_name,
            section_code: sectionCode,
            section_name: sectionName,
            content_code: contentCode,
            content_name: contentName,
            content_type: contentType,
            choice_code: choiceCode,
            choice_text: choiceText,
            choice_score: choiceScore,
            scale_display: "",
            is_mandatory: isMandatory == 1 ? "true" : "false",
            answer: "",
            goal_id: goalId,
            created_at: `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
        }

        // console.log('oneAnswer handleAddEditRankingAnswer', oneAnswer);
        const dt = manyAnswerReducer.filter(e => e.section_code == oneAnswer.section_code && e.content_code == oneAnswer.content_code && e.choice_code == oneAnswer.choice_code)
        const dtContent = manyAnswerReducer.filter(e => e.section_code == oneAnswer.section_code && e.content_code == oneAnswer.content_code )

        if (dt.length == 0) {
         
            dispatch(rootAction.surveyForm.setManyAnswer(oneAnswer));
            deleteMandatoryCheck(sectionCode,`${contentCode}-${choiceCode}`)
        } else {
            

            handleEditFieldManyAnswerSameContent(sectionCode,contentCode,choiceCode, CONSTANTS.SURVEY_FORM_REDUX.CHOICE_SCORE, choiceScore);

        }
         
        setChange(!change);
    }

        useEffect(()=>{
        },[manyAnswerReducer])
    

    if (content.content_type == CONSTANTS.CONTENT_TYPE.SCALE) {
        
        return (
            content.content_choice.map((ch, i) => {
                return (
                    widthWindow >= 1000 ?
                        <Col key={i}>

                            <div className="custom-radio-label"
                            >
                                <input
                                    type="radio"
                                    id={`radio-${sectionCode}-${content.content_code}-${i}`}
                                    name={`${sectionCode}-${content.content_code}`}
                                    value={ch.choice_code}
                                    checked={Array.isArray(oldContent) && oldContent.length != 0 && ch.choice_code == oldContent[0].choice_code ? true : false}
                                    onChange={() => handleAddEditChoicesAnswer(
                                        sectionCode, sectionName, content.content_code, content.content_name, content.content_type, ch.choice_code, ch.choice_text, ch.choice_score, content.goal_id, content.is_mandatory
                                    )}

                                />
                                <label htmlFor={`radio-${sectionCode}-${content.content_code}-${i}`}>{ch.choice_text}</label>
                            </div>
                        </Col> :
                        <Row key={i}>
                            <div className="d-flex my-1" >
                                <input className='me-2' type="radio" name={`${sectionCode}-${content.content_code}`} id={`radio-${sectionCode}-${content.content_code}-${i}`} value={ch.choice_code}
                                    checked={Array.isArray(oldContent) && oldContent.length != 0 && ch.choice_code == oldContent[0].choice_code ? true : false}
                                    onChange={() => handleAddEditChoicesAnswer(
                                        sectionCode, sectionName, content.content_code, content.content_name, content.content_type,  ch.choice_code, ch.choice_text, ch.choice_score, content.goal_id,content.is_mandatory
                                    )}
                                />
                                <label htmlFor={`radio-${sectionCode}-${content.content_code}-${i}`}>{ch.choice_text}</label>
                            </div>
                        </Row>
                )
            })
        )
    }
    else if (content.content_type == CONSTANTS.CONTENT_TYPE.SINGLE_CHOICE) {
        return (
            content.content_choice.map((ch, i) => {
                return (
                    <Row key={i}>
                        <div className="d-flex my-1" >
                            <input className='me-2' type="radio" id={`radio-${sectionCode}-${content.content_code}-${i}`} name={`${sectionCode}-${content.content_code}`} value={ch.choice_code}
                                checked={Array.isArray(oldContent) && oldContent.length != 0 && ch.choice_code == oldContent[0].choice_code ? true : false}
                                onChange={() => handleAddEditChoicesAnswer(
                                    sectionCode, sectionName, content.content_code, content.content_name, content.content_type,  ch.choice_code, ch.choice_text, ch.choice_score, content.goal_id, content.is_mandatory
                                )}
                            />
                            <label htmlFor={`radio-${sectionCode}-${content.content_code}-${i}`}>{ch.choice_text}</label>
                        </div>
                    </Row>
                )
            })
        )
    } else if (content.content_type == CONSTANTS.CONTENT_TYPE.MULTIPLE_CHOICE) {
        return (
            content.content_choice.map((ch, i) => {
                const oldContentFilter = oldContent.filter(e => e.choice_code == ch.choice_code);
                // console.log(`length oldcontent filter ${oldContentFilter.length}`);
                // console.log(oldContentFilter);
                return (
                    <Row key={i}>
                        <div className="d-flex my-1" >

                            <input className='me-2' type="checkbox" id={`checkbox-${sectionCode}-${content.content_code}-${i}`} name={`${sectionCode}-${content.content_code}`} value={ch.choice_code} style={{ cursor: 'pointer' }}
                                checked={Array.isArray(oldContentFilter) && oldContentFilter.length != 0 && ch.choice_code == oldContentFilter[0].choice_code ? true : false}
                                onChange={() => handleMultipleChoiceAnswer(
                                    sectionCode, sectionName, content.content_code, content.content_name, content.content_type, ch.choice_code, ch.choice_text, ch.choice_score, content.goal_id, content.is_mandatory
                                )}
                            />
                            <label htmlFor={`checkbox-${sectionCode}-${content.content_code}-${i}`} style={{ cursor: 'pointer' }}>{ch.choice_text}</label>
                        </div>
                    </Row>
                )
            })
        )
    } else if (content.content_type == CONSTANTS.CONTENT_TYPE.RANKING) {

        return (
          
            <div className="col-12">
               {/* <div style={{fontStyle:'italic', color:'#7749F8'}}>Select the order of the available answer options!</div> */}
               <div className="col-12 d-flex justify-content-evenly my-2">
                    <div className="col-10 d-flex justify-content-center " style={{fontWeight:'bold'}}>
                                Options
                    </div>
                    <div className="col-1 d-flex justify-content-center " style={{fontWeight:'bold'}}>
                                Sequence
                    </div>
               </div>
            {
               Array.isArray(content.content_choice) & content.content_choice.length != 0 ?
               content.content_choice.map((ch,i)=>{
                    const oldContentFilter = oldContent.filter(e => e.choice_code == ch.choice_code);

                    return(
                    <div className="col-12 d-flex justify-content-evenly my-2" key={i}>
                        
                        <div className="col-10 d-flex  align-items-center tw-rounded-lg tw-p-1 tw-shadow-sm tw-border tw-border-gray-400">
                            {ch.choice_text}
                        </div>

                        <div className="col-1 d-flex justify-content-center ">
                        <DropdownOnly 
                       style={Array.isArray(oldContentFilter) && oldContentFilter.length != 0 && ch.choice_code == oldContentFilter[0].choice_code ? {minWidth:'100%'} :{}}
                        default_item={Array.isArray(oldContentFilter) && oldContentFilter.length != 0 && ch.choice_code == oldContentFilter[0].choice_code ? oldContentFilter[0].choice_score : "Select"}
                        dropdown_item={
                            content.content_choice.length != 0 ? (
                                content.content_choice.map((itm, j) => {
                                return <Dropdown.Item key={j} onClick={()=>handleAddEditRankingAnswer(
                                    sectionCode,
                                    sectionName,
                                    content.content_code,
                                    content.content_name,
                                    content.content_type,
                                    ch.choice_code,ch.choice_text,(j+1),content.goal_id,content.is_mandatory
                                )}>{j+1}</Dropdown.Item>;
                              })
                            ) : (
                              <Dropdown.Item>-</Dropdown.Item>
                            )
                          }
                        />
                        </div>
                        
                    </div>
                    )
               }): <div></div>
            }

{/* {
               Array.isArray(content.content_choice) & content.content_choice.length != 0 ?
               content.content_choice.map((ch,i)=>{
                    return(
                    <div className="col-12 my-4 "  key={i}>
                       
                            <DropdownOnly
                              dropdown_item={
                                content.content_choice.length != 0 ? (
                                    content.content_choice.map((itm, j) => {
                                    return <Dropdown.Item key={j} >
                                        {itm.choice_text}
                                        </Dropdown.Item>;
                                  })
                                ) : (
                                  <Dropdown.Item>-</Dropdown.Item>
                                )
                              }
                            />
                        </div>
                        
            
                    )
               }): <div></div>
            } */}
            
            </div>
        )

    } else {

        return (
         
            <TextAreaOnly
            handleOnChange={handleAddAnswerInput}
            value={oldContent.length == 1 ? oldContent[0].answer : ''}
            input_width={"100%"}
            id={`${sectionCode}-${content.content_code}`} />
           
        )
    }
}


const contentTemplate =
{
    content_type: "",
    content_code: "",
    content_name: "",
    item: "",
    item_in_eng: "",
    help: "",
    score_same_as_scale: 0,
    score: 0,
    goal_id: 0,
    group: "",
    dimension: "",
    aspect: "",
    is_mandatory: "false",
    has_box_answer: 0,
    content_choice: [
        {
            choice_code: "",
            choice_text: "",
            choice_score: 0
        },
    ]
}

