import { useState } from "react";
import { useEffect } from "react";
import { useDeps } from "../../../../shared/context/DependencyContext";
import screenToast from "../../../../shared/hook/ScreenToast";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../../common/constants";

function useSelectHistoryTP({ limit, pageNumber, search, buName, deptName, level }) {
    const { generalService, adminService , picService } = useDeps();
   const [targetData, setTargetData] = useState([]);
   const [totalItem, setTotalItem] = useState(0);
   const [buData, setBuData] = useState([{ business_unit_name: "ALL", business_unit_code: "11111111" }]);
   const [departmentData, setDepartmentData] = useState([{ department_name: "ALL", department_code: "11111111" }]);
   const [levelData, setLevelData] = useState([{ level_user: "ALL" }]);
   const showToast = screenToast();
   const user = useSelector((state) => state.user);

   useEffect(() => {
      onGetBUData();
      onGetDepartmentData();
      onGetLevelData();
   }, []);

   useEffect(() => {
    // business_unit_name,deparment_name,level,search,limit,page_number
      let filterData = {
        business_unit_name: buName == 'ALL' ? '' : buName,
        deparment_name: deptName == 'ALL' ? '' : deptName,
        level: level == 'ALL' ? '' : level,
        search: search,
        limit: limit,
        page_number: pageNumber,
      }

      onFilterTargetParticipant(filterData);
    //   onFilterTargetParticipant(filterData.bu_name,filterData.dept_name,filterData.level,search,limit,pageNumber);

   }, [limit, pageNumber, search, buName, deptName, level]);

   

   const onFilterTargetParticipant = async (data) => {
      try {
        if (user.role == CONSTANTS.ROLE.ADMIN) {
            const response = await adminService.targetParticipantHistoryService.getForSelectHistoryTP(data);
            

            if (response.status == 'SUCCESS') {
                if (response.data != 'NO DATA') {
                   setTotalItem(response.data.total_item);
                   setTargetData(response.data.data);
                } else {
                   showToast.warning("No data found")
                }
             } else if (response.status == 'UNKNOWN ERROR') {
                setTotalItem(0);
                setTargetData([]);
             }
        }else {
            const response = await picService.targetParticipantHistoryService.getForSelectHistoryTPPICUrl(data);

            if (response.status == 'SUCCESS') {
                if (response.data != 'NO DATA') {
                   setTotalItem(response.data.total_item);
                   setTargetData(response.data.data);
                } else {
                   showToast.warning("No data found")
                }
             } else if (response.status == 'UNKNOWN ERROR') {
                setTotalItem(0);
                setTargetData([]);
             }
        }
      } catch (error) {
         console.log(error)
      }
   }

   const onGetBUData = async () => {
      try {
         const response = await generalService.generalDataService.getBusinessUnitData();

         if (response.status == 'SUCCESS') {
            setBuData([...buData, ...response.data]);
         } else {
            setBuData([]);
         }
      } catch (error) {
         console.log(error)
      }
   }

   const onGetDepartmentData = async () => {
      try {
         const response = await generalService.generalDataService.getDepartmentData();

         if (response.status == 'SUCCESS') {
            setDepartmentData([...departmentData, ...response.data]);
         } else {
            setDepartmentData([]);
         }
      } catch (error) {
         console.log(error)
      }
   }

   const onGetLevelData = async () => {
      try {
         const response = await generalService.generalDataService.getLevelData();

         if (response.status == 'SUCCESS') {
            setLevelData([...levelData, ...response.data]);
         } else {
            setLevelData([]);
         }
      } catch (error) {
         console.log(error)
      }
   }

   

   return {
      targetData,
      totalItem,
      buData,
      departmentData,
      levelData,
   }
}

export default useSelectHistoryTP
