import { Dropdown } from "react-bootstrap";
import Pagination from "../../../../shared/component/table_property/Paginatination";
import { SearchColumn } from "../../../../shared/component/table_property/SearchColumn";
import { ShowEntries } from "../../../../shared/component/table_property/ShowEntries";
import { InputOnlyForDropdown } from "../../../../shared/component/input/Input";
import { DropdownOnly } from "../../../../shared/component/dropdown/DropdownInput";
import { ButtonOutlinePrimary, ButtonPrimary } from "../../../../shared/component/button/Button";
import { rootAction } from "../../../../redux/RootAction";
import { CONSTANTS } from "../../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import {  useEffect, useState } from "react"
import useRefs from "react-use-refs";
import useSelectHistoryTP from "./useSelectHistoryTP";
import screenAlert from "../../../../shared/hook/SweetAlert";


function SelectHistoryViewTPUpdate(params) {
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchBar, setSearchBar] = useState('');
    const [search, setSearch] = useState('');
    const [searchBU, setSearchBU] = useState('');
    const [searchDept, setSearchDept] = useState('');
    const [searchLevel, setSearchLevel] = useState('');
    const [buName, setBUName] = useState('');
    const [deptName, setDeptName] = useState('');
    const [level, setLevel] = useState('');
    const [participantData, setParticpantData] = useState([]);
    const [buNameRef, deptNameRef, levelRef] = useRefs();
   const participantReducer = useSelector((state) => state.update_participant_multi_rater);
   const participantReducerSR = useSelector((state) => state.update_participant_single_rater);
    const dispatch = useDispatch();
    const { targetData, totalItem, buData, departmentData, levelData } = useSelectHistoryTP({ limit, pageNumber, search, buName, deptName, level });
    const showAlert = screenAlert();
    const currentUrl = window.location.pathname.split('/')[3];

    useEffect(() => {
        if (currentUrl == `${CONSTANTS.ROUTER.UPDATE_SINGLERATER}`) {
            setParticpantData(participantReducerSR)
        } else {
            setParticpantData(participantReducer);
        }
    }, []);

    const handleOnClickLimit = (val) => {
        setPageNumber(1);
        setLimit(val);
    }

    const previousPage = () => {
        if (pageNumber != 1) {
            setPageNumber(pageNumber - 1);

        }
    }

    const nextPage = () => {
        if (pageNumber != Math.ceil(totalItem / limit)) {
            setPageNumber(pageNumber + 1);
        }
    }

    const paginate = (pageNumber) => {
        setPageNumber(pageNumber);
    }

    const handleOnSubmitSearch = () => {
        setPageNumber(1);
        setSearch(searchBar);
    }

    const handleOnChangeText = (e) => {
        e.target.id == 'search_bar' && setSearchBar(e.target.value);
        e.target.id == 'search_bu' && setSearchBU(e.target.value);
        e.target.id == 'search_dept' && setSearchDept(e.target.value);
        e.target.id == 'search_level' && setSearchLevel(e.target.value);
    }


    const handleOnSelect = (ref, val) => {
        ref.current.id == 'business_unit' && setBUName(val);
        ref.current.id == 'business_unit' && setPageNumber(1);

        ref.current.id == 'department' && setDeptName(val);
        ref.current.id == 'department' && setPageNumber(1);

        ref.current.id == 'level' && setLevel(val);
        ref.current.id == 'level' && setPageNumber(1);
    }

    const handleOnCheck = (e, val) => {
        if (e.target.checked) {
            setParticpantData([...participantData, {
                nik: val.nik,
                full_name: val.full_name,
                email: val.email ,
                phone_number: val.phone_number,
                level: val.level,
                position: val.position ,
                business_unit_name: val.business_unit_name,
                deparment_name: val.deparment_name,
                role_in_survey_id: 1,
                is_internal: val.is_internal,
                is_active: 1,
                raters: [
                    {
                        nik: val.nik ,
                        full_name: val.full_name,
                        email: val.email ,
                        phone_number: val.phone_number,
                        dob:val.dob != '' ? val.dob : null,
                        gender:val.gender,
                        join_date:val.join_date != '' ? val.join_date : null,
                        level: val.level ,
                        position: val.position ,
                        business_unit_name: val.business_unit_name ,
                        deparment_name: val.deparment_name,
                        role_in_survey_id: 1,
                        is_internal: val.is_internal,
                        is_active: 1,
                        status: val.status != undefined ? val.status : 'not started'
                    }
                ]
            }]);
        }

        if (!e.target.checked) {
            setParticpantData(participantData.filter((el) => el.nik != val.nik));
        }
    }

    const handleOnSubmitParticipant = () => {
        // console.log('participant data di add from tp', participantData);
        showAlert.info({
            text: "Are you sure to save participants for survey?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
                if (currentUrl == `${CONSTANTS.ROUTER.UPDATE_SINGLERATER}`) {
                   dispatch(rootAction.updateSingleRater.setParticipantSRAction(participantData));
                }else{
                   dispatch(rootAction.updateMultiRater.setParticipantMRAction(participantData));
                }
                navigate(-1);
             }
        });
    }

    return(
        <div className="container tw-h-max">

        {/* Header */}
        <div className='my-4 d-flex'><h4>Target Participant History</h4></div>
        <div className="my-4 d-flex justify-content-between align-items-center">
            <span className="fst-italic">Please select the employees you want to add as target participants, then <strong>click the top right button.</strong></span>
            <div className="d-flex">
                <div className="me-2">
                    <ButtonOutlinePrimary label="Back" onClick={() => navigate(-1)} />
                </div>
                <div>
                    <ButtonPrimary
                        label='Add to Project'
                        onClick={handleOnSubmitParticipant}
                    />
                </div>
            </div>
        </div>

        {/* Card */}
        <div className='card shadow p-4 border-0'>

            {/* Header */}
            <div className="col-12">

                {/* Title */}
                <div className="col-12 mb-4">
                    <h5 className="">Filter Data by</h5>
                </div>

                {/* Filter */}
                <div className="col-12 d-flex justify-content-between mb-4">
                        <div className="col-3 tw-w-[30%]">
                            <div>Business Unit</div>
                            <div>
                                <DropdownOnly
                                    id={'business_unit'}
                                    innerRef={buNameRef}
                                    default_item={Array.isArray(buData) && buData.length > 0 ? (buName != '' ? buData.find((el) => el.business_unit_name == buName)?.business_unit_name : buData.find((el) => el.business_unit_name == 'ALL')?.business_unit_name) : null}
                                    search_bar={
                                        <InputOnlyForDropdown
                                            id={'search_bu'}
                                            type="text"
                                            placeholder="Search Business Unit"
                                            value={searchBU}
                                            handleOnChange={handleOnChangeText}
                                            input_width={'100%'}
                                        />
                                    }
                                    dropdown_item={
                                        Array.isArray(buData) && buData.length > 0 ? (searchBU != '' ? (buData.filter((el) => el.business_unit_name != null ? el.business_unit_name.toString().toLowerCase().includes(searchBU.toLowerCase()) : '').map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_name)}>{itm.business_unit_name}</Dropdown.Item>
                                            )
                                        })) :
                                            (buData.map((itm, i) => {
                                                return (
                                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(buNameRef, itm.business_unit_name)}>{itm.business_unit_name}</Dropdown.Item>
                                                )
                                            }))
                                        ) :
                                            (<Dropdown.Item>Select</Dropdown.Item>)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3 tw-w-[30%]">
                            <div>Department</div>
                            <div>
                                <DropdownOnly
                                    id={'department'}
                                    innerRef={deptNameRef}
                                    default_item={Array.isArray(departmentData) && departmentData.length > 0 ? (deptName != '' ? departmentData.find((el) => el.department_name == deptName)?.department_name : departmentData.find((el) => el.department_name == 'ALL')?.department_name) : null}
                                    search_bar={
                                        <InputOnlyForDropdown
                                            id={'search_dept'}
                                            type="text"
                                            placeholder="Search Department"
                                            value={searchDept}
                                            handleOnChange={handleOnChangeText}
                                            input_width={'100%'}
                                        />
                                    }
                                    dropdown_item={
                                        Array.isArray(departmentData) && departmentData.length > 0 ? (searchDept != '' ? (departmentData.filter((el) => el.department_name != null ? el.department_name.toString().toLowerCase().includes(searchDept.toLowerCase()) : '').map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_name)}>{itm.department_name}</Dropdown.Item>
                                            )
                                        })) :
                                            (departmentData.map((itm, i) => {
                                                return (
                                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(deptNameRef, itm.department_name)}>{itm.department_name}</Dropdown.Item>
                                                )
                                            }))
                                        ) :
                                            (<Dropdown.Item>Select</Dropdown.Item>)
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3 tw-w-[30%]">
                            <div>Level</div>
                            <div>
                                <DropdownOnly
                                    id={'level'}
                                    innerRef={levelRef}
                                    default_item={Array.isArray(levelData) && levelData.length > 0 ? (level != '' ? levelData.find((el) => el.level_user == level)?.level_user : levelData.find((el) => el.level_user == 'ALL')?.level_user) : null}
                                    search_bar={
                                        <InputOnlyForDropdown
                                            id={'search_level'}
                                            type="text"
                                            placeholder="Search Level"
                                            value={searchLevel}
                                            handleOnChange={handleOnChangeText}
                                            input_width={'100%'}
                                        />
                                    }
                                    dropdown_item={
                                        Array.isArray(levelData) && levelData.length > 0 ? (searchLevel != '' ? (levelData.filter((el) => el.level_user != null ? el.level_user.toLowerCase().includes(searchLevel.toLowerCase()) : '').map((itm, i) => {
                                            return (
                                                <Dropdown.Item key={i} onClick={() => handleOnSelect(levelRef, itm.level_user)}>{itm.level_user}</Dropdown.Item>
                                            )
                                        })) :
                                            (levelData.map((itm, i) => {
                                                return (
                                                    <Dropdown.Item key={i} onClick={() => handleOnSelect(levelRef, itm.level_user)}>{itm.level_user}</Dropdown.Item>
                                                )
                                            }))
                                        ) :
                                            (<Dropdown.Item>Select</Dropdown.Item>)
                                    }
                                />
                            </div>
                        </div>
                </div>
            </div>
            
            <div className='row justify-content-between'>
                <div className='col-4 d-flex flex-row align-items-center mt-4' >
                    <div>
                        Show
                    </div>
                    <ShowEntries default_item={limit} onClick_item={handleOnClickLimit} />
                    <div className=''>
                        entries
                    </div>
                </div>
    
                <div className='col-3 d-flex align-items-center'>
                    {/* <div className="tooltips">
                        <button className="me-2 btn btn-light " onClick={handleReset}>
                                <FontAwesomeIcon icon={faArrowsRotate} />
                        </button>   
                        <span className="tooltiptext">Reset</span>
                    </div> */}
                    <SearchColumn id={'search_bar'} onChange_input={handleOnChangeText} onClick_btn={handleOnSubmitSearch} />
                </div>
    
            </div>

             {/* Table */}
             <div className='table-responsive'>
                    <table className="table table-striped table-bordered" style={{ tableLayout: 'fixed' }}>
                        <thead>
                        <tr>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '3rem' }} >&#x2714;</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '4rem' }} >No</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '15rem' }}>Name</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '6rem' }}>NIK</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '27rem' }}>Email</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '4rem' }}>Level</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '20rem' }}>Department</th>
                                <th style={{ verticalAlign: 'middle', textAlign: 'center', width: '20rem' }}>Business Unit</th>

                        </tr>
                    
                    </thead>
                    <tbody>
                        { targetData.length != 0 ?
                            targetData.map((dt,i) => {
                            return (
                            <tr key={i} style={{textAlign:'center', verticalAlign:'middle'}}>
                                    <td >
                                        <div className="form-check d-flex justify-content-center" style={{scale:'1.5'}}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onClick={(e) => handleOnCheck(e, dt)}
                                                id={`flexCheckDefault`}
                                                style={{cursor:'pointer'}}
                                                checked={participantData.length > 0 && participantData.find((val) => val.nik == dt.nik) ? true : false}

                                            />
                                        </div>
                                    </td>
                                    {
                                    pageNumber == 1 ? <td>{i+1}</td> : <td>{(i+1) + (limit*(pageNumber-1))}</td>
                                    }
                                    <td>{dt.full_name}</td>
                                    <td>{dt.nik}</td>
                                    <td>{dt.email}</td>
                                    <td>{dt.level}</td>
                                    <td>{dt.deparment_name}</td>
                                    <td>{dt.business_unit_name}</td>
                                    
                            </tr>)
                            })
                            :
                            <tr>
                            <td style={{textAlign:'center'}} colSpan={7}>No data</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <Pagination showing={targetData.length} totalItem={totalItem} showItem={limit} previousPage={previousPage} nextPage={nextPage} paginate={paginate} limit={pageNumber}/>
  
        </div>
      </div>
    )
}

export default SelectHistoryViewTPUpdate