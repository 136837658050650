import { DELETE_ADD_RATER, DELETE_ADD_RATER_INTERNAL, DELETE_ALL_ADD_RATER, DELETE_ALL_ADD_RATER_INTERNAL, DELETE_DATA_SEE_RATERS, SET_ADD_RATER, SET_ADD_RATER_INTERNAL, SET_DATA_SEE_RATERS } from "./AddRaterType";

const initAddRaters = [];

const rater ={
    nik: "",
    full_name: "",
    email: "",
    phone_number: "",
    level: "",
    position: "",
    business_unit_name: "",
    deparment_name: "",
    role_in_survey_id: 0,
    is_internal: 0,
    status: ""
  }

const initSeeRatersData = {
   survey_code:'',
   tp_id:0,
   tp_email:'',
   tp_name:'',
   tp_nik:'',
   user_email:'',
   
}

export function addRatersReducer(state = initAddRaters, action) {
    switch (action.type) {
       case SET_ADD_RATER:
          return [...state, action.data];
       case DELETE_ADD_RATER:
        return state.filter((el) => el.email != action.data.email );
       case DELETE_ALL_ADD_RATER:
        return initAddRaters
        default:
          return state;
    }
 }

 export function dataSeeRatersReducer(state = initSeeRatersData, action) {
   switch (action.type) {
      case SET_DATA_SEE_RATERS:
         return {
            ...state,
            [action.data.field]: action.data.payload
         }
      case DELETE_DATA_SEE_RATERS:
         return initSeeRatersData
      default:
         return state;
   }
}

