export const scaleScore = [
  {
    item: 'same as scale',
    eventKey: '1',
    val: 1
  },
  {
    item: 'reverse of scale',
    eventKey: '2',
    val: 0
  }
];

export const contentLayoutChoices = [
  {
    item: 'Horizontal',
    eventKey: '1',
    val: 'hor'
  },
  {
    item: 'Vertical',
    eventKey: '2',
    val: 'ver'
  }
];

export const contentTypeList = [
  {
    item: 'Scale',
    eventKey: '1',
    content_type: 'scale'
  },
  {
    item: 'Single Choice',
    eventKey: '2',
    content_type: 'single_choice'
  },
  {
    item: 'Multiple Choice',
    eventKey: '3',
    content_type: 'multiple_choice'
  },
  {
    item: 'Ranking',
    eventKey: '4',
    content_type: 'ranking'
  },
  {
    item: 'Text Input',
    eventKey: '5',
    content_type: 'text_input'
  },
];

// Bentuk data treeData harus ada children walaupun array kosong, soalnya kalau gak dikasih children nanti error di tampilannya
export const treeData = [
  {
    id: 1,
    name: 'Section 1',
    children: [
      {
        id: 1,
        name: 'Question 1',
        children: []
      },
      {
        id: 2,
        name: 'Question 2',
        children: []
      },
      {
        id: 3,
        name: 'Question 3',
        children: []
      },
    ]
  },
  {
    id: 2,
    name: 'Section 2',
    children: [
      {
        id: 1,
        name: 'Question 1',
        children: []
      },
      {
        id: 2,
        name: 'Question 2',
        children: []
      },
      {
        id: 3,
        name: 'Question 3',
        children: []
      },
    ]
  },

];

export const answerPerRowList = [1, 2, 3, 4, 5, 6];
export const itemFree = ['item1', 'item2', 'item3', 'item4', 'item5'];
export const scaleDisplayList = [
  {
    id: 1,
    text: 'Circle & Label',
    val: 'circle_label'
  },
  {
    id: 2,
    text: 'Drag Scale',
    val: 'drag_scale'
  },
  {
    id: 3,
    text: 'Number',
    val: 'number'
  },
  {
    id: 4,
    text: 'Number & Label',
    val: 'number_label'
  },
  {
    id: 5,
    text: 'Square & Label',
    val: 'square_label'
  }
];
export const scaleLabelData = (section_id) => {
  return {
    section_id: section_id,
    content_choice: [
      {
        scale_id: 1,
        choice_code: '',
        choice_text: 'Agree',
        choice_score: 1
      },
      {
        scale_id: 2,
        choice_code: '',
        choice_text: 'Neutral',
        choice_score: 2
      },
      {
        scale_id: 3,
        choice_code: '',
        choice_text: 'Disagree',
        choice_score: 3
      }

    ]
  }
}

export const contentTypeListWithoutScale = [
  {
    item: 'Single Choice',
    eventKey: '2',
    content_type: 'single_choice'
  },
  {
    item: 'Multiple Choice',
    eventKey: '3',
    content_type: 'multiple_choice'
  },
  {
    item: 'Ranking',
    eventKey: '4',
    content_type: 'ranking'
  },
  {
    item: 'Text Input',
    eventKey: '5',
    content_type: 'text_input'
  },
];

export const contentTypeListOnlyScale = [
  {
    item: 'Scale',
    eventKey: '1',
    content_type: 'scale'
  },
  {
    item: 'Text Input',
    eventKey: '5',
    content_type: 'text_input'
  },
]

export const scaleLabelDataCopy = (section_id,content_choice) => {
  return {
    section_id: section_id,
    content_choice: content_choice
  }
}