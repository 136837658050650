import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import LoadingBar from 'react-top-loading-bar';
import { useDeps } from '../../../shared/context/DependencyContext';
import { FormPageBorder } from '../FormPageTemplate';
import {
    ButtonOutlinePrimary,
    ButtonPreview
} from '../../../shared/component/button/Button';
import TCLogo from '../../../assets/images/logo_horizontal_tc.png';
import { getControlId } from '../utils/useSection';
import useRouting from '../utils/useRouting';
import { CONTENT_SECTIONS as CONTROLS } from '../constants';
import { Chapter, SubChapter, Text } from '../components/previews/Text';
import RadarChartData from '../components/previews/RadarChartData';
import BarChartData from '../components/previews/BarChartData';
import DataTable from '../components/previews/DataTable';
import CommentData from '../components/previews/CommentData';
import DisplayData from '../components/previews/DisplayData';
import DevelopmentForm from '../components/previews/DevelopmentForm';
import DemographyData from '../components/previews/DemographyData';
import SummaryTable from '../components/previews/SummaryTable';

const getInfo = (info, group_by) => {
    if (group_by) {
        return [
            { label: 'Survey Name', text: info?.survey_name },
            { label: 'Business Unit', text: info?.bussines_unit_name },
            { label: 'Department', text: info?.department_name },
            { label: 'Creation Date', text: info?.created_at }
        ];
    } else {
        return [
            { label: 'Report for', text: info?.user_fullname },
            { label: 'Business Unit', text: info?.bussines_unit_name },
            { label: 'Department', text: info?.department_name },
            { label: 'Position', text: info?.position_user },
            { label: 'Creation Date', text: info?.created_at }
        ];
    }
};

const ReportTemplatePreview = () => {
    const navigate = useNavigate();
    const { urlListTemplate } = useRouting();
    const { adminService } = useDeps();
    const reportUrl = useSelector((state) => state.reportPreviewUrl);
    const [progress, setProgress] = useState(0);
    const [postData, setPostData] = useState({});

    const handleNavigateBack = (e) => {
        navigate(reportUrl.__prev, { state: { survey_code: reportUrl.survey_code } });
    };

    const onGetPreviewReport = async () => {
        setProgress(10);
        try {
            const response =
                await adminService.reportTemplateService.getPreviewReportData({
                    report_type: reportUrl.report_type,
                    survey_code: reportUrl.survey_code,
                    tpl_code: reportUrl.tpl_code,
                    participant_id: reportUrl.participant_id,
                    group_by: reportUrl.group_by
                });
            setProgress(30);

            if (response && response.status == 'SUCCESS' && response.data != 'NO DATA') {
                setPostData(response.data);
                setProgress(60);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setProgress(100);
        }
    };

    useEffect(() => {
        if (reportUrl) {
            if (reportUrl.group_by) {
                setPostData({ pages: [] });
            } else {
                setPostData({ info: {}, sections: [] });
            }
            onGetPreviewReport();
        } else {
            navigate(urlListTemplate());
        }
    }, []);

    return (
        <Container className="tw-h-max ">
            <LoadingBar
                color="#7749F8"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Row className="justify-content-md-center">
                <Col md={10}>
                    <div className="d-flex justify-content-end mb-4">
                        <div className="me-2">
                            <ButtonPreview label="PREVIEW RESULT" disabled={true} />
                        </div>
                        <div>
                            <ButtonOutlinePrimary
                                label="Back"
                                onClick={handleNavigateBack}
                            />
                        </div>
                    </div>

                    {reportUrl.group_by ? (
                        Object.hasOwn(postData, 'pages') && postData.pages.map((pgData) => (
                            <ReportTemplatePage
                                {...pgData}
                                group_by={reportUrl.group_by}
                            />
                        ))
                    ) : (
                        <ReportTemplatePage {...postData} group_by={reportUrl.group_by} />
                    )}
                </Col>
            </Row>
        </Container>
    );
};

const ReportTemplatePage = ({ info, sections, group_by }) => {
    return (
        <>
            <FormPageBorder>
                <div style={{ marginBottom: '150px' }}>
                    <img src={TCLogo} />
                </div>

                <div style={{ marginBottom: '150px' }}>
                    <h2>{info?.report_title}</h2>
                </div>

                <dl className="row">
                    {getInfo(info, group_by).map((d) => (
                        <>
                            <dt className="col-sm-3">{d.label}</dt>
                            <dd className="col-sm-9">: {d.text}</dd>
                        </>
                    ))}
                </dl>
            </FormPageBorder>

            <FormPageBorder className="mt-3 mb-3">
                {Array.isArray(sections) &&
                    sections.map((section) => {
                        const cid = getControlId(section.section_code);
                        const props = {
                            contentId: section.section_code,
                            result: section.result
                        };
                        return (
                            <div key={section.section_index} className="mb-3">
                                {cid === CONTROLS.CHAPTER ? (
                                    <Chapter>{section.content}</Chapter>
                                ) : cid === CONTROLS.SUB_CHAPTER ? (
                                    <SubChapter>{section.content}</SubChapter>
                                ) : cid === CONTROLS.TEXT ? (
                                    <Text>{section.content}</Text>
                                ) : cid === CONTROLS.DATA ? (
                                    <DisplayData {...props} ordered={true} />
                                ) : cid === CONTROLS.RADAR_CHART_DATA ? (
                                    <RadarChartData
                                        {...props}
                                        defaultTheme={section.theme}
                                    />
                                ) : cid === CONTROLS.BAR_CHART_DATA ? (
                                    <BarChartData
                                        {...props}
                                        defaultTheme={section.theme}
                                    />
                                ) : cid === CONTROLS.DATA_TABLE ? (
                                    <DataTable {...props} />
                                ) : cid === CONTROLS.DEVELOPMENT_FORM ? (
                                    <DevelopmentForm {...props} />
                                ) : cid === CONTROLS.COMMENTS_DATA ? (
                                    <CommentData {...props} />
                                ) : cid === CONTROLS.DEMOGRAPHY_DATA ? (
                                    <DemographyData {...props} />
                                ) : cid === CONTROLS.SURVEY_DATA ? (
                                    <DisplayData {...props} />
                                ) : cid === CONTROLS.SUMMARY_TABLE ? (
                                    <SummaryTable {...props} />
                                ) : null}
                            </div>
                        );
                    })}
            </FormPageBorder>
        </>
    );
};

export default ReportTemplatePreview;
