import { useState } from "react";
import { useDeps } from "../../shared/context/DependencyContext";
import { rootAction } from "../../redux/RootAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../common/constants";

function useAuthomaticLoginByLink() {
    const { participantService } = useDeps();
    const [surveyDetailData, setSurveyDetailData] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleParticipantRaterMRFormData = (field,payload) => {
        dispatch(rootAction.surveyForm.setDataParticipantRaterMRForm({
            field: field,
            payload: payload
        }));
       
      }

      const handleParticipantRaterSRFormData = (field,payload) => {
        dispatch(rootAction.singleSurveyForm.setDataParticipantRaterSRForm({
            field: field,
            payload: payload
        }));
       
      }

    const onGetDetailSurveyData = async (survey_code,raters_id,participant_id,token) => {
        dispatch(rootAction.user.userLoginAction({
            token: token,
            role: 3,
        }));
       let data = {
       survey_code: survey_code,
       s_participant_id: participant_id,
       s_raters_id: raters_id
     }
        try {
          const response = await participantService.loginByLinkService.getDetailSurveyFormData(data);

          if (response.status == 'SUCCESS') {
            // console.log('response survey data', response.data);
            dispatch(rootAction.user.userLoginAction({
                email: response.data.raters.raters_email,
                name: response.data.raters.raters_name,
                nik: response.data.raters.raters_nik
            }));
            setSurveyDetailData(response.data);
            if (response.data.type == 'multi') {
                dispatch(rootAction.surveyForm.setMRDetailData(response.data))
                dispatch(rootAction.surveyForm.deleteAllManyAnswer());
                handleParticipantRaterMRFormData('s_raters_id',raters_id);
                handleParticipantRaterMRFormData('s_participant_id',participant_id);
                handleParticipantRaterMRFormData('survey_code',survey_code);
                handleParticipantRaterMRFormData('target_nik',response.data.participant.participant_nik);
                handleParticipantRaterMRFormData('target_full_name',response.data.participant.participant_name);
                handleParticipantRaterMRFormData('target_email',response.data.participant.participant_email);
                handleParticipantRaterMRFormData('survey_name',response.data.setting.survey_name);
                // handleParticipantRaterMRFormData('rater_role',rater_role);
                handleParticipantRaterMRFormData('rater_nik',response.data.raters.raters_nik);
                handleParticipantRaterMRFormData('rater_email',response.data.raters.raters_email);
                handleParticipantRaterMRFormData('rater_bu',response.data.raters.business_unit_name);
                handleParticipantRaterMRFormData('rater_dept',response.data.raters.deparment_name);
                navigate(`${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_MULTIRATER_SURVEY_FORM_AUTO}`);
            }else {
                dispatch(rootAction.singleSurveyForm.setSRDetailData(response.data))
                dispatch(rootAction.singleSurveyForm.deleteAllManyAnswerSR());
                handleParticipantRaterSRFormData('s_raters_id',raters_id);
                handleParticipantRaterSRFormData('s_participant_id',participant_id);
                handleParticipantRaterSRFormData('survey_code',survey_code);
                handleParticipantRaterSRFormData('target_nik',response.data.participant.participant_nik);
                handleParticipantRaterSRFormData('target_full_name',response.data.participant.participant_name);
                handleParticipantRaterSRFormData('target_email',response.data.participant.participant_email);
                handleParticipantRaterSRFormData('survey_name',response.data.setting.survey_name);
                // handleParticipantRaterMRFormData('rater_role',rater_role);
                handleParticipantRaterSRFormData('rater_nik',response.data.raters.raters_nik);
                handleParticipantRaterSRFormData('rater_email',response.data.raters.raters_email);
                handleParticipantRaterSRFormData('rater_bu',response.data.raters.business_unit_name);
                handleParticipantRaterSRFormData('rater_dept',response.data.raters.deparment_name);
                navigate(`${CONSTANTS.ROUTER.PARTICIPANT_DASHBOARD}/${CONSTANTS.ROUTER.PARTICIPANT_SINGLERATER_SURVEY_FORM_AUTO}`);
            }

          }
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('final onGetDetailSurveyData')
        }
    }


    return{
        onGetDetailSurveyData,
        surveyDetailData
    }
}

export default useAuthomaticLoginByLink;