import { Modal } from "react-bootstrap";
import { InputWithLabel } from "../../../../shared/component/input/Input";
import { ButtonOutlinePrimary } from "../../../../shared/component/button/Button";
import { useEffect } from "react";

function ReadDetailTargetParticipantView({ size, show, onHide, participant = {} , survey_detail = []}) {

    return (
        <Modal
            size={size}
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton >
                <Modal.Title id="contained-modal-title-vcenter">Detail Participant {participant.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.nik} label={'NIK'} />
                </div>
                <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.full_name} label={'Name'} />
                </div>
                <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.email != undefined && typeof participant.email == 'string' ? participant.email.toLowerCase() : participant.email} label={'Email'} />
                </div>
                <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.position} label={'Position'} />
                </div>
                {/* <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.level} label={'Level'} />
                </div> */}
                <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.deparment_name} label={'Department'} />
                </div>
                <div className="my-2">
                    <InputWithLabel disabled={true} value={participant.business_unit_name} label={'Business Unit'} />
                </div>
                <div className="fw-bold my-2">Detail Surveys</div>
                <div className='mt-1 table-responsive'>
                    <table className="table table-striped table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>Survey Code</th>
                                <th>Survey Type</th>
                                <th>Survey Name</th>
                                <th>Status</th>
                                <th>Periode</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(survey_detail) && survey_detail.length != 0 ? 
                                survey_detail.map((dt,i)=> {
                                    return (
                                        <tr key={i}>
                                            <td>{dt.survey_code}</td>
                                            <td>dt.survey_type</td>
                                            <td>{dt.survey_name}</td>
                                            <td>{dt.status}</td>
                                            <td>{dt.start_date} - {dt.end_date}</td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan={4} style={{verticalAlign:'middle', textAlign:'center'}}>No Data</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <ButtonOutlinePrimary label="Close" onClick={onHide} />
            </Modal.Footer>

        </Modal>
    )
}

export default ReadDetailTargetParticipantView;
