import { Dropdown, Modal } from "react-bootstrap";
import { DropdownWithLabel } from "../../../../../shared/component/dropdown/DropdownInput";
import { InputWithLabel, InputWithLabelButton } from "../../../../../shared/component/input/Input";
import { ButtonPrimary } from "../../../../../shared/component/button/Button";
import { useEffect, useState } from "react";
import { roleInSurvey } from "../participantData";
import useEditRatersParticipant from "./useEditRatersParticipant";
import screenToast from "../../../../../shared/hook/ScreenToast";

function ModalAddEditRaters({ size, show, onHide, title, type, data, handleOnSave , ratersType}) {
    // const [ratType, setRatType] = useState(ratersType);
    const [nik, setNik] = useState('');
    const [searchNik, setSearchNik] = useState('');
    const [raters, setRaters] = useState({});
    const { emailLoginAsParticipant,onCheckEmailAsParticipantByNIK } = useEditRatersParticipant({ nik: searchNik });
    const showToast = screenToast();
    const [error, setError] = useState({
        isEmpty: false,
        isInvalid: false,
        isInvalidFormat :false
    });

  useEffect (()=> {
    if (data) {
        setRaters(data)
        onCheckEmailAsParticipantByNIK(data.nik)
        // console.log('data NIK',data.nik);
    }
   
  },[data])

//   useEffect (()=> {
//     console.log('ERROR', error);
//     console.log('data==>', raters);
  
   
//   },[error,raters])

    useEffect(() => {
        // type == 'EDIT' && setRaters(data ?? {})
        // type == 'EDIT' && setRatType(data && data.is_internal)
        // type == 'ADD' && setRaters(participantData);

        if (Object.keys(raters).length !== 0) {
            !raters.email && setError({
                ...error,
                isEmpty: true
            });
            raters.email && setError({
                ...error,
                isEmpty: false
            });
           
        }
    
    }, [data, raters]);


    useEffect(() => {
        if (emailLoginAsParticipant && Object.keys(raters).length !== 0) {
            if (raters.email.toLowerCase() != emailLoginAsParticipant.toLowerCase()) {
                setError({
                    ...error,
                    isInvalid: true
                });
            }
        }
    }, [emailLoginAsParticipant]);

    // useEffect(() => {
    //    console.log('isInvalidFormat', error.isInvalidFormat);
    //    console.log('empty', error.isEmpty);

    // }, [error]);

    const handleOnChangeText = (e) => {
        e.target.id == 'nik' && setNik(e.target.value);
        e.target.id != 'nik' && setRaters({
            ...raters,
            [e.target.id]: e.target.value
        });

        // console.log('email eksternal', e.target.value);
        if (e.target.id == 'email') {
            if (e.target.value.length > 0 && !e.target.value.match(/^[a-zA-Z0-9_.-]+@([a-zA-Z0-9_-]+\.)+[A-Za-z]+$/)) {
                // console.log('EMAIL FORMAT', e.target.value);
                setError({
                    ...error,
                    isInvalidFormat: true
                })
            } else if (e.target.value.length > 0 && (emailLoginAsParticipant ? e.target.value.toLowerCase() == emailLoginAsParticipant.toLocaleLowerCase() : true)) {
                setError({
                    isEmpty: false,
                    isInvalid: false
                });
            } else if (e.target.value.length <= 0) {
                setError({
                    ...error,
                    isEmpty: true
                });
            } else if (emailLoginAsParticipant && e.target.value.toLowerCase() != emailLoginAsParticipant.toLocaleLowerCase()) {
                setError({
                    ...error,
                    isInvalid: true
                });
            }
        }
    }

 

    const handleOnSelect = (val) => {
        setRaters({
            ...raters,
            role_in_survey_id: val
        });
    }

    const handleOnClickSave = () => {
        let newRaters = {
            // level: raters.level_user ?? raters.level,
            // position: raters.position_user ?? raters.position,
            // business_unit_name: raters.bussines_unit_name ?? raters.business_unit_name,
            // deparment_name: raters.department_name ?? raters.deparment_name,
            // role_in_survey_id: raters.role_in_survey_id ?? roleInSurvey[1].id,
            // is_internal: raters.is_internal ?? ratersType,
            // is_active: 1,
            // status: raters.status ? raters.status : 'not started'
            nik: raters.nik != '' ? raters.nik : null,
            full_name: raters.full_name != '' ? raters.full_name : null,
            email: raters.email != '' ? raters.email : null,
            phone_number: raters.phone_number != '' ? raters.phone_number : null,
            dob: raters.dob != '' ? raters.dob : null,
            gender: raters.gender != '' ? raters.gender : null,
            join_date: raters.join_date != '' ? raters.join_date : null,
            level: raters.level_user != '' ? raters.level_user : raters.level != '' ? raters.level : null,
            position: raters.position_user != '' ? raters.position : null,
            business_unit_name: raters.bussines_unit_name != '' ? raters.bussines_unit_name : raters.business_unit_name ?  raters.business_unit_name : null,
            deparment_name: raters.department_name != '' ? raters.department_name : raters.deparment_name ? raters.deparment_name : null,
            role_in_survey_id: raters.role_in_survey_id ?? roleInSurvey[1].id,
            is_internal:  raters.is_internal ?? ratersType,
            is_active: 1,
            status: raters.status ? raters.status : 'not started'
        }

        let newRatersExternal = {
            full_name: raters.full_name,
            email: raters.email,
            is_internal: raters.is_internal ?? ratersType,
            is_active: 1,
            role_in_survey_id: raters.role_in_survey_id ?? roleInSurvey[1].id,
            status: raters.status ? raters.status : 'not started',
        }


        if (newRaters.full_name && newRaters.email) {
            if (ratersType == 1) {
                handleOnSave(newRaters);
            } else {
            handleOnSave(newRatersExternal);           
            }
        } else {
            showToast.error("Please input valid rater")
        }

        onHideModal();
        setRaters({});
        setNik('');
        setSearchNik('');
       
    }

    const onHideModal = () => {
        setRaters({});
        setError({
            isEmpty: false,
            isInvalid: false,
            isInvalidFormat:false
        });
        onHide(true)
    }

     return (
        <Modal
            size={size}
            show={show}
            onHide={onHideModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            <Modal.Header closeButton style={{ borderRadius: 0 }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {type} {ratersType == 1 ? 'Internal Rater' : 'External Rater'}
                </Modal.Title>
            </Modal.Header>

            {
                ratersType == 1 ?
                <Modal.Body>

                <InputWithLabel
                    id={'nik'}
                    label={'NIK'}
                    style={{ marginBottom: '1rem' }}
                    disabled={ true }
                    bg_input={ '#e9ecef'}
                    value={raters.nik ? raters.nik : ''}
                   
                />

                <InputWithLabel
                    id={'full_name'}
                    label={'Name'}
                    style={{ marginBottom: '1rem' }}
                    disabled={true}
                    bg_input={'#e9ecef'}
                    value={raters.full_name ? raters.full_name : ''}
                    handleOnChange={handleOnChangeText}
                />
                <InputWithLabel
                    id={'email'}
                    label={'Email'}
                    style={{ marginBottom: '1rem' }}
                    value={raters.email ? raters.email?.toLowerCase() : ''}
                    handleOnChange={handleOnChangeText}
                    disabled={type == 'EDIT' ? true : false}
                    bg_input={type == 'EDIT' ? 'rgba(171, 181, 190, 0.20)' : ''}
                />
                {
                    (error.isInvalid || error.isEmpty || error.isInvalidFormat) &&
                    <div className="d-flex flex-row mb-3">
                        <span className="tw-w-1/5"></span>
                        <span className="tw-w-4/5" style={{ fontSize: '0.8rem', color: 'red' }}>
                            {
                                error.isInvalid &&
                                <>
                                    This user has been registered with the email {emailLoginAsParticipant.toLowerCase()} <br></br>
                                    Please edit with the registered email, or contact admin to change.<br></br>
                                </>
                            }
                            {
                                error.isEmpty && <>Please add rater email before saving.</>
                            }
                             {
                                error.isInvalidFormat && <>Please enter the correct email format.</>
                            }
                        </span>
                    </div>
                }
                <InputWithLabel
                    id={'position'}
                    label={'Position'}
                    style={{ marginBottom: '1rem' }}
                    disabled={true}
                    bg_input={'#e9ecef'}
                    value={raters.position ?? (raters.position_user ? raters.position_user : '')}
                    handleOnChange={handleOnChangeText}
                />
                <InputWithLabel
                    label={'Level'}
                    style={{ marginBottom: '1rem' }}
                    disabled={true}
                    bg_input={'#e9ecef'}
                    value={raters.level ?? (raters.level_user ? raters.level_user : '')}
                />
                <InputWithLabel
                    id={'business_unit_name'}
                    label={<div>
                        Company / <br></br> Business Unit
                    </div>}
                    style={{ marginBottom: '1rem' }}
                    disabled={true}
                    bg_input={'#e9ecef'}
                    value={raters.business_unit_name ?? (raters.bussines_unit_name ? raters.bussines_unit_name : '')}
                    handleOnChange={handleOnChangeText}
                />

                <DropdownWithLabel
                    label={'Realtionship'}
                    default_item={raters.role_in_survey_id ? roleInSurvey.find((el) => el.id == raters.role_in_survey_id).role_name : roleInSurvey[1].role_name}
                    disabled={raters.role_in_survey_id === 1 ? true : false}
                    dropdown_item={
                        roleInSurvey.length != 0 ? (roleInSurvey.map((itm, i) => {
                            return (
                                <Dropdown.Item key={i} onClick={() => handleOnSelect(itm.id)}>{itm.role_name}</Dropdown.Item>
                            )
                        })
                        ) :
                            (<Dropdown.Item>Select</Dropdown.Item>)
                    }
                />
            </Modal.Body>
            :
            <Modal.Body> 
                <InputWithLabel
                    id={'full_name'}
                    label={'Name'}
                    style={{ marginBottom: '1rem' }}
                    
                    bg_input={ 'white'}
                    value={raters.full_name ? raters.full_name : ''}
                    handleOnChange={handleOnChangeText}
                />
                <InputWithLabel
                    id={'email'}
                    label={'Email'}
                    style={{ marginBottom: '1rem' }}
                    value={raters.email ? raters.email?.toLowerCase() : ''}
                    handleOnChange={handleOnChangeText}
                    disabled={type == 'EDIT' ? true : false}
                    bg_input={type == 'EDIT' ? 'rgba(171, 181, 190, 0.20)' : ''}
                />
                {
                    (error.isInvalid || error.isEmpty || error.isInvalidFormat) &&
                    <div className="d-flex flex-row mb-3">
                        <span className="tw-w-1/5"></span>
                        <span className="tw-w-4/5" style={{ fontSize: '0.8rem', color: 'red' }}>
                            {
                                error.isInvalid &&
                                <>
                                    This user has been registered with the email {emailLoginAsParticipant.toLowerCase()} <br></br>
                                    Please edit with the registered email, or contact admin to change.<br></br>
                                </>
                            }
                            {
                                error.isEmpty && <>Please add rater email before saving.</>
                            }
                             {
                                error.isInvalidFormat && <>Please enter the correct email format.</>
                            }
                        </span>
                    </div>
                }
               <DropdownWithLabel
                    label={'Relationship'}
                    default_item={raters.role_in_survey_id ? roleInSurvey.find((el) => el.id == raters.role_in_survey_id).role_name : roleInSurvey[1].role_name}
                    disabled={raters.role_in_survey_id === 1 ? true : false}
                    dropdown_item={
                        roleInSurvey.length != 0 ? (roleInSurvey.map((itm, i) => {
                            return (
                                <Dropdown.Item key={i} onClick={() => handleOnSelect(itm.id)}>{itm.role_name}</Dropdown.Item>
                            )
                        })
                        ) :
                            (<Dropdown.Item>Select</Dropdown.Item>)
                    }
                />
            </Modal.Body>

            }

            <Modal.Footer className="p-1">
                <ButtonPrimary label="Save" onClick={handleOnClickSave} disabled={(error.isEmpty || error.isInvalid || error.isInvalidFormat) ? true : false} />
            </Modal.Footer>
        </Modal>
    )

   
}

export default ModalAddEditRaters;