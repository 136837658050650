import { Dropdown } from "react-bootstrap"
import { IconButtonDelete, IconButtonDuplicate } from "../../../../../shared/component/button/IconButton"
import { DropdownWithLabel, ToggleWithLabel } from "../../../../../shared/component/dropdown/DropdownInput"
import { InputWithLabel, TextAreaWithLabel } from "../../../../../shared/component/input/Input"
import { ButtonContent } from "../../../../../shared/component/button/Button"
import { contentTypeList, contentTypeListOnlyScale, contentTypeListWithoutScale, scaleScore } from "../contentData"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { rootAction } from "../../../../../redux/RootAction"
import useRefs from "react-use-refs"
import screenToast from "../../../../../shared/hook/ScreenToast"
import useQuestionContent from "./useQuestionContent"
import screenAlert from "../../../../../shared/hook/SweetAlert"

function QuestionContent() {
    const contentView = useSelector((state) => state.view_content_multi_rater);
    const settingReducer = useSelector((state) => state.setting_multi_rater);
    const contentReducer = useSelector((state) => state.section_multi_rater.length > 0 && state.section_multi_rater.find((el) => el.section_id == contentView.section_id).content.find(el => el.content_id == contentView.content_id));
    const scaleReducer = useSelector((state) => state.scale_choice_multi_rater);
    const dispatch = useDispatch();
    const [contentTypeRef, goalRef, scaleScoreRef] = useRefs();
    const { goalData } = useQuestionContent();
    const [counter, setCounter] = useState(0);
    const showToast = screenToast();
    const showAlert = screenAlert();
    const sectionReducer = useSelector((state) => state.section_multi_rater.length > 0 && state.section_multi_rater.find((el) => el.section_id == contentView.section_id));


    useEffect(() => {
        let current = contentReducer.content_choice.length > 0 ? (contentReducer.content_choice[contentReducer.content_choice.length - 1].choice_id) + 1 : 1;
        setCounter(current);
    }, []);

    useEffect(() => {
        if (!scaleReducer.find((el) => el.section_id == contentView.section_id) && contentReducer.content_type == 'scale') {
            dispatch(rootAction.multiRater.editContentMRAction({
                section_key: contentView.section_id,
                content_key: contentView.content_id,
                field: 'content_type',
                payload: ''
            }));
            showToast.error('Please set scale first');
        }
        if (scaleReducer.find((el) => el.section_id == contentView.section_id) && (contentReducer.content_type == 'single_choice'  || contentReducer.content_type == 'multiple_choice' || contentReducer.content_type == 'ranking')) {
            dispatch(rootAction.multiRater.editContentMRAction({
                section_key: contentView.section_id,
                content_key: contentView.content_id,
                field: 'content_type',
                payload: 'scale'
            }));
            showToast.error('Please set content type again');
        }
    }, [contentReducer.content_type, contentReducer.score_same_as_scale]);

    const handleOnChangeText = (e, choice_id) => {
        if (e.target.id != 'choice_text' && e.target.id != 'choice_score') {
            dispatch(rootAction.multiRater.editContentMRAction({
                section_key: contentView.section_id,
                content_key: contentView.content_id,
                field: e.target.id,
                payload: e.target.value
            }));
        } else {
            dispatch(rootAction.multiRater.editContentChoiceMRAction({
                section_key: contentView.section_id,
                content_key: contentView.content_id,
                choice_key: choice_id,
                field: e.target.id,
                payload: e.target.value
            }));
        }
    }

    const handleOnChangeToggle = (e) => {
        dispatch(rootAction.multiRater.editContentMRAction({
            section_key: contentView.section_id,
            content_key: contentView.content_id,
            field: e.target.id,
            payload: e.target.checked ? 1 : 0
        }));
    }

    const handleOnSelect = (ref, val) => {
        dispatch(rootAction.multiRater.editContentMRAction({
            section_key: contentView.section_id,
            content_key: contentView.content_id,
            field: ref.current.id,
            payload: val
        }));
    }

    const handleDeleteContent = () => {
        dispatch(rootAction.multiRater.setContentViewMRAction({
            section_id: contentView.section_id,
            content_id: contentView.content_id - 1
        }));
        dispatch(rootAction.multiRater.deleteContentMRAction({
            section_key: contentView.section_id,
            content_key: contentView.content_id
        }));
    }

    const handleAddContentChoice = () => {
        dispatch(rootAction.multiRater.setContentChoiceMRAction({
            section_key: contentView.section_id,
            content_key: contentView.content_id,
            payload: {
                choice_id: counter,
                choice_code: '',
                choice_text: '',
                choice_score: 0,
            }
        }));
        setCounter(prevState => prevState + 1);
    }

    const handleDeleteContentChoice = (choice_id) => {
        dispatch(rootAction.multiRater.deleteContentChoiceMRAction({
            section_key: contentView.section_id,
            content_key: contentView.content_id,
            choice_key: choice_id
        }));
    }

    
    const handleCopyContent = (val) => {
        let content = {
            content_id: sectionReducer.content.length + 1,
            content_type: val.content_type,
            content_code: '',
            content_name: 'Question ' + (sectionReducer.content.length + 1),
            item: val.item,
            item_in_eng: val.item_in_eng,
            help: val.help,
            score_same_as_scale: val.score_same_as_scale,
            score: val.score,
            goal_id: val.goal_id,
            group_survey: val.group_survey,
            dimension: val.dimension,
            aspect: val.aspect,
            is_mandatory: val.is_mandatory,
            has_box_answer: val.has_box_answer,
            action_for_strength: val.action_for_strength,
            action_for_weakness: val.action_for_weakness,
            content_choice: val.content_choice
        }

        dispatch(rootAction.multiRater.setContentMRAction({
            key: sectionReducer.section_id,
            payload: content
        }));
        dispatch(rootAction.multiRater.setContentViewMRAction({
            section_id: sectionReducer.section_id,
            content_id: content.content_id
        }));
        // console.log('Content yg ci copy', content);
    }

    const alertToDuplicateContent = () => {
        showAlert.info({
            text: `Are you sure to duplicate ${contentReducer.content_name} ?`,
            buttons: {
                cancel: {
                    text: "Cancel",
                    className: "bg-white text-dark"
                },
                confirm: {
                    text: "Confirm",
                    value: true,
                    className: "bg-success"
                }
            }
        }).then(value => {
            if (value) {
               handleCopyContent(contentReducer)
            }
        });
    }

    return (
        <div className="col-9">

            <div className="col-12 border-bottom pb-1 d-flex justify-content-between align-items-center" style={{ boxShadow: 'inset 0 -0.1rem 0 0 rgb(0,0,0,0.2)', height: '6vh' }}>
                <div>

                </div>
                <h5>
                    Content Structure - Content
                </h5>
                <div>
                <IconButtonDuplicate onClick={alertToDuplicateContent}/>
                <IconButtonDelete onClick={handleDeleteContent} />
                </div>

            </div>

            <div className="col-12 row">
                <div className="col-8">
                    <div className="my-4" >
                    {
                    !scaleReducer.find((el) => el.section_id == contentView.section_id)?
                    <DropdownWithLabel
                            id={'content_type'}
                            innerRef={contentTypeRef}
                            label={'Content Type'}
                            default_item={contentReducer.content_type ? contentTypeList.find((el) => el.content_type === contentReducer.content_type).item : null}
                            dropdown_item={
                                contentTypeListWithoutScale.map((el, i) => {
                                    return (
                                        <Dropdown.Item
                                            key={i}
                                            eventKey={el.eventKey}
                                            onClick={() => handleOnSelect(contentTypeRef, el.content_type)}>
                                            {el.item}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        /> 
                    : 
                    <DropdownWithLabel
                            id={'content_type'}
                            innerRef={contentTypeRef}
                            label={'Content Type'}
                            default_item={contentReducer.content_type ? contentTypeList.find((el) => el.content_type === contentReducer.content_type).item : null}
                            dropdown_item={
                                contentTypeListOnlyScale.map((el, i) => {
                                    return (
                                        <Dropdown.Item
                                            key={i}
                                            eventKey={el.eventKey}
                                            onClick={() => handleOnSelect(contentTypeRef, el.content_type)}>
                                            {el.item}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        />
         
                    }
                        {/* <DropdownWithLabel
                            id={'content_type'}
                            innerRef={contentTypeRef}
                            label={'Content Type'}
                            default_item={contentReducer.content_type ? contentTypeList.find((el) => el.content_type === contentReducer.content_type).item : null}
                            dropdown_item={
                                contentTypeList.map((el, i) => {
                                    return (
                                        <Dropdown.Item
                                            key={i}
                                            eventKey={el.eventKey}
                                            onClick={() => handleOnSelect(contentTypeRef, el.content_type)}>
                                            {el.item}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        /> */}
                    </div>
                    {/* <div className="my-4">
                        <DropdownWithLabel
                            disabled={contentType == 1 || contentType == 5 ? true : false}
                            label={<div>
                                Answer <br></br> per row
                            </div>}
                            default_item={answerPerRowList[0]}
                            dropdown_item={
                                answerPerRowList.map((ans, i) => {
                                    return (
                                        <Dropdown.Item key={i}>
                                            {ans}
                                        </Dropdown.Item>
                                    )
                                })
                            }
                        />
                    </div> */}
                    <div className="my-4">
                        <ToggleWithLabel
                            id={'is_mandatory'}
                            label={'Mandatory'}
                            checked={contentReducer.is_mandatory}
                            handleChange={handleOnChangeToggle}
                        />
                    </div>
                    <div className="my-4">
                        <ToggleWithLabel
                            id={'has_box_answer'}
                            label={<div>
                                Answer Text Input
                            </div>}
                            checked={contentReducer.has_box_answer}
                            handleChange={handleOnChangeToggle}
                        />
                    </div>

                    {/* If question by Create New Question then the section below appears */}
                    <div className="my-4">
                        <DropdownWithLabel
                            id={'goal_id'}
                            innerRef={goalRef}
                            label={'Goal'}
                            default_item={(contentReducer.goal_id && Array.isArray(goalData) && goalData.length > 0) ? goalData.find((el) => el.id === contentReducer.goal_id)?.goal_name : null}
                            dropdown_item={
                                Array.isArray(goalData) && goalData.length != 0 ? (goalData.map((el, i) => {
                                    return (
                                        <Dropdown.Item key={i} onClick={() => handleOnSelect(goalRef, el.id)}>{el.goal_name}</Dropdown.Item>
                                    )
                                })
                                ) :
                                    (<Dropdown.Item>Select</Dropdown.Item>)
                            }
                        />
                    </div>
                    <div className="my-4">
                        <InputWithLabel id={'group_survey'} label={'Group'} value={contentReducer.group_survey} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <InputWithLabel id={'dimension'} label={'Dimension'} value={contentReducer.dimension} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <InputWithLabel id={'aspect'} label={'Aspect'} value={contentReducer.aspect} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <TextAreaWithLabel id={'action_for_strength'} label={<div>Action for <br></br> strength</div>} value={contentReducer.action_for_strength} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <TextAreaWithLabel id={'action_for_weakness'} label={<div>Action for <br></br> weakness</div>} value={contentReducer.action_for_weakness} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className={`my-4 ${settingReducer.language == 'en' ? 'd-none' : ''}`}>
                        <TextAreaWithLabel id={'item'} label={<div>Item (Main <br></br> Language )</div>} value={contentReducer.item} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className={`my-4 ${settingReducer.language == 'main' ? 'd-none' : ''}`}>
                        <TextAreaWithLabel id={'item_in_eng'} label={<div>Item  <br></br> (English) </div>} value={contentReducer.item_in_eng} handleOnChange={handleOnChangeText} />
                    </div>
                    <div className="my-4">
                        <TextAreaWithLabel id={'help'} label={'Help'} value={contentReducer.help} handleOnChange={handleOnChangeText} />
                    </div>

                    {/* CHOICES */}
                    {contentReducer.content_type == 'scale' || contentReducer.content_type == 'text_input' ? (<div></div>) : (
                        contentReducer.content_choice.length != 0 ? (
                            contentReducer.content_choice.map((el, i) => {
                                return (
                                    <div key={i}>
                                        <div className="my-4">
                                            <TextAreaWithLabel id={'choice_text'} label={`Text Choice ${i + 1}`} value={el.choice_text} handleOnChange={(e) => handleOnChangeText(e, el.choice_id)} />
                                        </div>
                                        {
                                            contentReducer.content_type == 'ranking' ? 
                                            <div className="my-4">
                                            </div> 
                                        :
                                            <div className="my-4">
                                            <TextAreaWithLabel id={'choice_score'} label={`Numeric Value Choice ${i + 1}`} value={el.choice_score} handleOnChange={(e) => handleOnChangeText(e, el.choice_id)} />
                                            </div>
                                        }
                                        <div className="col-4">
                                            <div className="d-flex justify-content-end">
                                                <ButtonContent label="Delete Choice" onClick={() => handleDeleteContentChoice(el.choice_id)} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : <div></div>
                    )}



                </div>

                {contentReducer.content_type == 'text_input' || contentReducer?.content_type == '' ?
                    (
                        <div className="col-4 mt-2"></div>
                    ) :
                    (
                        contentReducer.content_type == 'scale' ?
                            (
                                <div className="col-4 my-4">
                                    <div className="d-flex justify-content-end">
                                        <DropdownWithLabel
                                            id={'score_same_as_scale'}
                                            innerRef={scaleScoreRef}
                                            label={'Score'}
                                            label_width={'100%'}
                                            default_item={contentReducer.score_same_as_scale != undefined ? scaleScore.find((el) => el.val === contentReducer.score_same_as_scale).item : scaleScore.find((el) => el.val === 1).item}
                                            dropdown_item={
                                                scaleScore.map((el, i) => {
                                                    return (
                                                        <Dropdown.Item key={i} eventKey={el.eventKey} onClick={() => handleOnSelect(scaleScoreRef, el.val)}>
                                                            {el.item}
                                                        </Dropdown.Item>
                                                    )
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="col-4 my-4">
                                    <div className="d-flex justify-content-end">
                                        <ButtonContent label="+ Choice" onClick={handleAddContentChoice} />
                                    </div>
                                </div>
                            )
                    )
                }
            </div>

        </div>
    )
}

export default QuestionContent;