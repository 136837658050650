import { useState } from "react";
import { useDeps } from "../../../shared/context/DependencyContext";
import { useEffect } from "react";
import { CONSTANTS } from "../../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../../redux/RootAction";
import { useNavigate } from "react-router-dom";
import screenAlert from "../../../shared/hook/SweetAlert";
import { clearParticipantAccountData } from "../../../common/utils";
import useViewState from "../../../shared/hook/UseViewState";
import screenToast from "../../../shared/hook/ScreenToast";

function useMultiRaterForm() {
    const { participantService } = useDeps();
    const [surveyDetailData, setSurveyDetailData] = useState();
    const accountData = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const showAlert = screenAlert();
    const { viewState, setError, setSuccess } = useViewState();
    const showToast = screenToast();



   
    const mandatoryContentList = useSelector((state) => state.mandatoryContentList);

    useEffect(() => {
      if (viewState.isError == true) {
          showToast.error(viewState.errorMsg);
          setError(false, '');
      }

      if (viewState.isSuccess == true) {
          showToast.success(viewState.successMsg);
          setSuccess(false, '');
      }
  }, [viewState]);

    const handleLogout = async () => {
    clearParticipantAccountData(dispatch);
    dispatch(rootAction.user.userLogoutAction());
    navigate(CONSTANTS.ROUTER.LOGIN_AS_PARTICIPANT, { replace: true });

    }
   

    const onGetDetailSurveyData = async (survey_code) => {
       
        try {
          const response = await participantService.multiRaterSurveyFormService.getDetailSurveyFormData(survey_code);

          if (response.status == 'SUCCESS') {
            console.log('response survey data', response.data);
              setSurveyDetailData(response.data);
          }
        } catch (error) {
            console.log(error)
        } finally {
            // console.log('final onGetDetailSurveyData')
        }
    }

    const handleSubmitManyAnswer = async (data,is_auto_login) => {
        // console.log('data answer many',JSON.stringify(data));
        // console.log('data answer many data', data);
        try {
          const response = await participantService.multiRaterSurveyFormService.postManyAnswer(data);
          // console.log('AUTO LOGIN MR SURVEY', is_auto_login);

          if (response.status == 'SUCCESS') {
              // setSuccess(true, 'Submitted Successfully')
              // hapus redux dr many answer
              if (is_auto_login) {
                // console.log('Response',response);
                handleLogout()
                setSuccess(true, 'Submitted Successfully')

              } else {
                navigate(-1)
                setSuccess(true, 'Submitted Successfully')
              }
              dispatch(rootAction.surveyForm.deleteAllManyAnswer())
              dispatch(rootAction.surveyForm.clearDataParticipantRaterMRForm())
          }
        } catch (error) {
            console.log(error)
            setError(true,'FAILED')
        } finally {
            // console.log('final onGetDetailSurveyData')
        }
    }

   


    return {
        surveyDetailData,
        handleSubmitManyAnswer,
        
        onGetDetailSurveyData
        
    }
}

export default useMultiRaterForm;


// const SurveyABC = {
//     survey_code: "SURVEYCODE123",
//     type: "Multi",
//     created_by: "Admin",
//     setting: {
//       survey_name: "Survey ABC Test",
//       description_setting: "Deskripsi survey",
//       anonimity: 0,
//       language: "English",
//       welcome_text: "Text Selesai",
//       finish_text: "Text Selesai",
//       invitation_text: "Text Undangan",
//       reminder_text: "Text Pengingat",
//       reminder_interval_in_days: 3
//     },
//     participant:
//       {
//         nik: "10001212",
//         full_name: "Dipta",
//         email: "dipta@mail.com",
//         phone_number: "0921730198264",
//         level: "6",
//         position: "PCD Specialist",
//         business_unit_name: "HRGS",
//         deparment_name: "PCD",
//         role_in_survey_id: 1,
//         is_internal: 1,
//         is_active: 1,
//         raters: 
//           {
//             nik: "12345",
//             full_name: "Renaldi",
//             email: "renaldi@mail.com",
//             phone_number: "0987654321",
//             level: "6",
//             position: "DP Specialist",
//             business_unit_name: "HRGS",
//             deparment_name: "PCD",
//             role_in_survey_id: 3,
//             is_internal: 1,
//             status: "statusnya"
//           },
          
        
//       },
      
//     total_section:3,
//     section: [
//       {
//         section_code: "1",
//         section_name: "Section 1",
//         description_section: "Deskripsi Section",
//         content_layout: "ver",
//         using_scale: 1,
//         scale_range: 5,
//         scale_display: "number",
//         content: [
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.SINGLE_CHOICE,
//             content_code: "1",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.SCALE,
//             content_code: "2",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.MULTIPLE_CHOICE,
//             content_code: "3",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.RANKING,
//             content_code: "4",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.TEXT_INPUT,
//             content_code: "5",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: []
//           }
//         ]
//       },
//       {
//         section_code: "2",
//         section_name: "Section 2",
//         description_section: "Deskripsi Section",
//         content_layout: "ver",
//         using_scale: 1,
//         scale_range: 5,
//         scale_display: "number",
//         content: [
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.SINGLE_CHOICE,
//             content_code: "1",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.SCALE,
//             content_code: "2",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.MULTIPLE_CHOICE,
//             content_code: "3",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.RANKING,
//             content_code: "4",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.TEXT_INPUT,
//             content_code: "5",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: []
//           }
//         ]
//       },
//       {
//         section_code: "3",
//         section_name: "Section 3",
//         description_section: "Deskripsi Section",
//         content_layout: "ver",
//         using_scale: 1,
//         scale_range: 5,
//         scale_display: "number",
//         content: [
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.SINGLE_CHOICE,
//             content_code: "1",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.SCALE,
//             content_code: "2",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.MULTIPLE_CHOICE,
//             content_code: "3",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.RANKING,
//             content_code: "4",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "1",
//                 choice_text: "Sangat Tidak Setuju",
//                 choice_score: 10
//               },
//               {
//                 choice_code: "2",
//                 choice_text: "Tidak Setuju",
//                 choice_score: 20
//               },
//               {
//                 choice_code: "3",
//                 choice_text: "Agak Tidak Setuju",
//                 choice_score: 30
//               },
//               {
//                 choice_code: "4",
//                 choice_text: "Biasa Saja",
//                 choice_score: 40
//               },
//               {
//                 choice_code: "5",
//                 choice_text: "Agak Setuju",
//                 choice_score: 50
//               },
//               {
//                 choice_code: "6",
//                 choice_text: "Setuju",
//                 choice_score: 60
//               },
//               {
//                 choice_code: "7",
//                 choice_text: "Sangat Setuju",
//                 choice_score: 70
//               }
//             ]
//           },
//           {
//             content_type: CONSTANTS.CONTENT_TYPE.TEXT_INPUT,
//             content_code: "5",
//             content_name: "Nama Content",
//             item: "Bila pribadi ini bekerja sama dengan rekan kerja, keterampilan interpersonal (bersoliasasi) apa yang dia tunjukkan?",
//             item_in_eng: "item in eng",
//             help: "pilih salah satu",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "grup",
//             dimension: "Dimensi",
//             aspect: "aspect",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: []
//           }
//         ]
//       },

      
//     ]
//   }

// const SurveyResponse = {
//     survey_code: "",
//     type: "",
//     created_by: "",
//     setting: {
//       survey_name: "",
//       description_setting: "",
//       anonimity: 0,
//       language: "",
//       welcome_text: "",
//       finish_text: "",
//       invitation_text: "",
//       reminder_text: "",
//       reminder_interval_in_days: 0
//     },
//     participant:
//       {
//         nik: "",
//         full_name: "",
//         email: "",
//         phone_number: "",
//         level: "",
//         position: "",
//         business_unit_name: "",
//         deparment_name: "",
//         role_in_survey_id: 0,
//         is_internal: 0,
//         is_active: 0,
//         raters: 
//           {
//             nik: "",
//             full_name: "",
//             email: "",
//             phone_number: "",
//             level: "",
//             position: "",
//             business_unit_name: "",
//             deparment_name: "",
//             role_in_survey_id: 0,
//             is_internal: 0,
//             status: ""
//           }
        
//       }
//     ,
//     total_section:1,
//     section: [
//       {
//         section_code: "",
//         section_name: "",
//         description_section: "",
//         content_layout: "",
//         using_scale: 1,
//         scale_range: 5,
//         scale_display: "",
//         content: [
//           {
//             content_type: "",
//             content_code: "",
//             content_name: "",
//             item: "",
//             item_in_eng: "",
//             help: "",
//             score_same_as_scale: 1,
//             score: 70,
//             goal_id: 1,
//             group: "",
//             dimension: "",
//             aspect: "",
//             is_mandatory: 1,
//             has_box_answer: 1,
//             content_choice: [
//               {
//                 choice_code: "0",
//                 choice_text: "",
//                 choice_score: 0
//               },
//             ]
//           },
//         ]
//       },
//     ]
//   }

// const targetParticipantEmail = 'dipta@mail.com';

// const raterTemplate = {
//             nik: "",
//             full_name: "",
//             email: "",
//             phone_number: "",
//             level: "",
//             position: "",
//             business_unit_name: "",
//             deparment_name: "",
//             role_in_survey_id: 0,
//             is_internal: 0,
//             status: ""
// }

