import { useEffect, useState } from "react";
import { useDeps } from "../../../../shared/context/DependencyContext";
import { CONSTANTS } from "../../../../common/constants";
import { useSelector } from "react-redux";
import screenToast from "../../../../shared/hook/ScreenToast";
import useViewState from "../../../../shared/hook/UseViewState";

function useTabMonitoring() {
    const {adminService, picService} = useDeps();
    const [allTP, setAllTP] = useState([]);
    const [showItem, setShowItem] = useState(50);
    const [searchText, setSearchText] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const user = useSelector((state) => state.user);
    const showToast = screenToast();
    const { viewState, setError, setSuccess } = useViewState();
    const [progress, setProgress] = useState(0);


    useEffect(() => {
      if (viewState.isError == true) {
          showToast.error(viewState.errorMsg);
          setError(false, '');
      }

      if (viewState.isSuccess == true) {
          showToast.success(viewState.successMsg);
          setSuccess(false, '');
      }
    }, [viewState]);


    const onGetAllTP = async (survey_code) => {
      setProgress(10);
        try {
          setProgress(30);
            if (user.role == CONSTANTS.ROLE.ADMIN) {
              const response = await adminService.multiRaterService.getDetailSurveyMultirater(survey_code);
            setProgress(60);
              
              if (response.status == 'SUCCESS') {
                setAllTP(response.data.participant);
                setTotalItem(response.data.participant.length);
                // console.log("response",response);
              }
            } else {
              const response = await picService.multiRaterService.getDetailSurveyMultiraterPICUrl(survey_code);
            setProgress(60);
              
              if (response.status == 'SUCCESS') {
                setAllTP(response.data.participant);
                setTotalItem(response.data.participant.length);
              }
            }

        } catch (error) {
            console.log(error)
        } finally {
            // console.log('finally ')
          setProgress(100);

        }
    }

  const handleshowItem = (item) => {
      setShowItem(item);
  }

  const handleInputSearchChange = (e) => {
      setSearchText(e.target.value);
  }

  const handleSearchData = () => {
      if (pageNumber == 1) {
      } else {
          setPageNumber(1);
      }
  }

  const completion =  (arrRaters=[]) => {
    // console.log(arrRaters);
    let jumlah_complete = 0;
     for (let index = 0; index < arrRaters.length ; index++) {
      if (arrRaters[index].status == 'done' || arrRaters[index].status == 'Done') {
        jumlah_complete = jumlah_complete + 1;
      }
      
    }
    return (
      ((jumlah_complete/arrRaters.length)*100).toFixed(1)
    ) 
  }


    return{
        allTP,
        pageNumber,
        totalItem,
        setPageNumber,
        setShowItem,
        showItem,
        setTotalItem,
        searchText,
        setSearchText,
        handleInputSearchChange,
        handleSearchData,
        handleshowItem,
        completion,
        onGetAllTP,
        progress,
        setProgress
       
    }
    
}
export default useTabMonitoring;

const participant= [
    {
      nik: "", 
      full_name: "", 
      email: "", 
      phone_number: "", 
      level: "", 
      position: "", 
      business_unit_name: "", 
      deparment_name: "", 
      role_in_survey_id: 1, 
      is_internal: 1, 
      is_active: 1, 
      raters:  [
        {
          nik: "", 
          full_name: "",
          email: "", 
          phone_number: "", 
          level: "", 
          position: "", 
          business_unit_name: "", 
          deparment_name: "", 
          role_in_survey_id: 1,
          is_internal: 1,
          status: "",
        }
      ]
    }
  ]

  const participantTP= [
    {
      nik: "nik", 
      full_name: "name TP", 
      email: "email TP", 
      phone_number: "number tp", 
      level: "level tp", 
      position: "position tp", 
      business_unit_name: "bu tp", 
      deparment_name: "dep tp", 
      role_in_survey_id: 1, 
      is_internal: 1, 
      is_active: 1, 
      raters:  [
        {
          nik: "nik rat 1", 
          full_name: "name rat",
          email: "email rat", 
          phone_number: "number rat", 
          level: "level rat", 
          position: "position rat", 
          business_unit_name: "bu rat", 
          deparment_name: "dep rat", 
          role_in_survey_id: 1,
          is_internal: 1,
          status: "done",
        },
        {
            nik: "nik rat 2", 
            full_name: "name rat",
            email: "email rat", 
            phone_number: "number rat", 
            level: "level rat", 
            position: "position rat", 
            business_unit_name: "bu rat", 
            deparment_name: "dep rat", 
            role_in_survey_id: 2,
            is_internal: 1,
            status: "done",
          },
          {
            nik: "nik rat 3", 
            full_name: "name rat",
            email: "email rat", 
            phone_number: "number rat", 
            level: "level rat", 
            position: "position rat", 
            business_unit_name: "bu rat", 
            deparment_name: "dep rat", 
            role_in_survey_id: 3,
            is_internal: 1,
            status: "not yet",
          }
      ]
    },
    {
      nik: "nik", 
      full_name: "name TP 2", 
      email: "email TP", 
      phone_number: "number tp", 
      level: "level tp", 
      position: "position tp", 
      business_unit_name: "bu tp", 
      deparment_name: "dep tp", 
      role_in_survey_id: 1, 
      is_internal: 1, 
      is_active: 1, 
      raters:  [
        {
          nik: "nik rat  21", 
          full_name: "name rat",
          email: "email rat", 
          phone_number: "number rat", 
          level: "level rat", 
          position: "position rat", 
          business_unit_name: "bu rat", 
          deparment_name: "dep rat", 
          role_in_survey_id: 1,
          is_internal: 1,
          status: "not started",
        },
        {
            nik: "nik rat 2 2", 
            full_name: "name rat",
            email: "email rat", 
            phone_number: "number rat", 
            level: "level rat", 
            position: "position rat", 
            business_unit_name: "bu rat", 
            deparment_name: "dep rat", 
            role_in_survey_id: 2,
            is_internal: 1,
            status: "done",
          },
          
      ]
    }
  ]